@use "style/_variable" as *;

.relationFutures {
  background:
    repeating-linear-gradient(to left, transparent, transparent rem(5), $c_border rem(5), $c_border rem(5 + 5)) top left / 100% 1px no-repeat,
    repeating-linear-gradient(to right, transparent, transparent rem(5), $c_border rem(5), $c_border rem(5 + 5)) bottom right / 100% 1px no-repeat,
    repeating-linear-gradient(to bottom, transparent, transparent rem(5), $c_border rem(5), $c_border rem(5 + 5)) top left / 1px 100% no-repeat,
    repeating-linear-gradient(to top, transparent, transparent rem(5), $c_border rem(5), $c_border rem(5 + 5)) top right / 1px 100% no-repeat;
  background-color: $c_bg;


  @include mq(narrow) {
    padding: rem(18) rem(15);
    margin: rem(20) 0 0;
  }
  @include mq(wide) {
    display: flex;
    gap: rem(64);
    padding: rem(20) rem(30);
    margin: rem(72) 0 0;
  }
}

.title {

  @include tracking(26);

  line-height: 1.4;

  @include mq(narrow) {
    font-size: rem(16);
  }
  @include mq(wide) {
    font-size: rem(18);
  }
}

.layoutBr {

  @include mq(narrow) {
    display: none;
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: rem(16);
  padding: rem(4) 0;
  margin: 0;

  @include mq(narrow) {
    margin: rem(18) 0 0;
  }
}


.item {
  display: flex;
  align-items: center;

  @include mq(narrow) {
    gap: rem(15);
  }

  @include mq(wide) {
    gap: rem(15);
  }
}

// No {no}
.no {
  @include rect(rem(60), rem(24));
  @include tracking(10);

  position: relative;
  min-width: rem(60);
  padding: 0.3em 0 0 rem(31);
  margin: 0 0 auto;
  clip-path: polygon(0 0, 0 60%, 25% 100%, 100% 100%, 100% 40%, 75% 0, 0 0);
  font-family: $f_en_bold;
  font-size: rem(18);
  line-height: 1;
  color: $c_darkness;
  background: $c_lightness;
}

.noUnit {
  @include rect(rem(9), rem(7));

  position: absolute;
  bottom: rem(6);
  left: rem(18);
}

.label{
  margin: -0.2em 0; // line-heightの上下スペースと親要素paddingの被りを調整
  color: currentColor;
  text-decoration: none;
}

.labelDecorationInline {
  @include tracking(80);
  @include tmp_inline-link;

  font-feature-settings: "palt";
  line-height: 1.7;

  @include mq(narrow) {
    font-size: rem(14);
    font-weight: 500;
  }

  @include mq(wide) {
    font-size: rem(18);
    font-weight: bold;
  }

  @media (any-hover: hover) {
    .label:hover & {
      @include tmp_inline-link-hover-animation;
    }
  }

  strong {
    @include mq(narrow) {
      font-weight: 500;
    }
  }
}