@use "style/_variable" as *;

.indexItem {
  display: grid;
  word-break: keep-all; // brかwbrタグでのみ改行許可

  // 13だけ2カラムぶち抜き
  &:last-child {
    @include mq(wide) {
      grid-column: 1 / 3;
    }
  }
}

.link {
  color: currentColor;
  text-decoration: none;
}

// no keyword
.header {
  display: flex;
  gap: rem(16);
  align-items: center;
}

// No {no}
.no {
  @include tracking(40);

  @include rect(rem(60), rem(24));

  position: relative;
  padding: rem(1) 0 0 rem(30);
  clip-path: polygon(0 0, 0 60%, 25% 100%, 100% 100%, 100% 40%, 75% 0, 0 0);
  font-family: $f_en_bold;
  font-size: rem(18);
  color: $c_darkness;
  background: $c_lightness;

  // Windowsはフォント上下のスペースが大きいので位置調整
  [data-is-windows="true"] & {
    padding: 0 0 rem(1) rem(30);
  }
}

.noUnit {
  @include rect(rem(9.6), rem(6.6));

  position: absolute;
  bottom: rem(6);
  left: rem(17.5);
}


// 人 ✕ {keyword}
.keyword {
  @include tracking(40);

  position: relative;
  display: inline-flex;
  gap: 0.66em;
  align-items: center;
  height: rem(36);
  padding: 0 1.25em;
  font-weight: bold;
  line-height: 1;
  border: 1px solid $c_subtext;
  border-radius: rem(999);

  @include mq(narrow) {
    font-size: rem(12);
  }

  @include mq(wide) {
    font-size: rem(13);
  }
}

.textImage {
  @include rect(rem(14), rem(13));

  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  margin: auto;
  color: $c_border;
}

.human {
  font-size: rem(13);
  color: transparent;
}

.corroborate {
  font-size: rem(12);
  color: #ffffffa6;
}


// 本文
.body {
  @include tracking(80);

  margin: 0.9em 0 0;
  font-weight: lighter;
  font-feature-settings: "palt";
  line-height: 1.3; // 32 / 24
  color: #fff9;

  @include mq(narrow) {
    font-size: rem(24);
  }

  @include mq(wide) {
    font-size: rem(30);
  }

  @media (any-hover: hover) {
    transition: color $t_ease;

    .link:hover & {
      color: $c_lightness;
    }
  }

  strong {
    font-weight: lighter;
  }
}