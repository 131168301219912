@use 'style/_variable' as *;

.layoutNavigation {
  top: 0;
  z-index: 1;

  @include mq(narrow) {
    position: absolute;
  }

  @include mq(wide) {
    position: fixed;
    bottom: 0;
    left: rem(16);
    display: flex;  // "100年後の未来の13の当たり前"を下端に寄せるためにflexを指定
    width: rem(326);
    padding: rem(30 + 20 + 30 + 96 + 36) rem(16) rem(48);
    overflow: auto;
    transition: opacity $t_ease;
  }

  // TOP→13の未来詳細: artworkGLのトランジションが再生中はナビを非表示にする
  &[class*="nowTransition"] {
    opacity: 0;
  }

  // 13の未来一覧: 画面上に固定させず本文と一緒にスクロールさせる。
  &[class*="isFuturesIndex"] {

    @include mq(wide) {
      position: absolute;
      min-height: rem(862);

      [class*="GlobalNavigation_screen"] {
        opacity: 0;
      }
    }
  }

  // 13の未来詳細: ヘッダー部分は画面上に固定させ本文の背後に潜らせ、メニューは前面に残したい。
  // ヘッダーだけダミーに差し替えので非表示にする。
  &[class*="isFuturesDetail"] {

    @include mq(narrow) {
      [class*="LayoutHeader_layoutHeader"] {
        display: none;
      }
    }
  }
}
