@use 'style/_variable' as *;

.copyArea {
  @include mq(narrow) {
    padding: rem(36) 0 rem(64);
    margin: rem(81) 0 0;
    background-image: url('images/bg-copyArea.svg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
  @include mq(wide) {
    // ナビをぱんくずとフッターの左側へせり出させるため絶対配置にするので、relativeを指定しこの枠を配置の起点に設定
    position: relative;
    margin: rem(100) 0 0;
    background-image: url('images/bg-copyArea-pc.svg');
    background-repeat: no-repeat;
    background-position-y: rem(14);
    background-size: 100%;
  }

}

// ナビ
.layoutNavigation {
  @include mq(narrow) {
    display: none;
  }
  @include mq(wide) {
    // ナビをぱんくずとフッターの左側へせり出させるため、絶対配置にして枠外まで高さを確保している
    position: absolute;
    min-height: rem(862);

    [class*="GlobalNavigation_screen"] {
      opacity: 0;
    }
  }
}

// 本文
.article {
  @include mq(narrow) {
    padding: 0 rem(15);
  }
  @include mq(wide) {
    width: rem(1440 - (16 + 326 + 66));
    padding: rem(138) rem(32) rem(158);
    margin: 0 0 0 auto;
  }
}

.title {
  text-align: center;

  @include mq(narrow) {
    margin-bottom: rem(30);
    font-size: rem(16);
  }

  @include mq(wide) {
    margin-bottom: rem(42);
    font-size: rem(24);
  }
}

.paragraph {
  @include tracking(30);

  font-weight: 500;
  line-height: 2;
  text-align: center;

  @include mq(narrow) {
    font-size: rem(16);
  }

  @include mq(wide) {
    font-size: rem(20);
    line-height: 2.55;
  }

  ~ .paragraph {

    @include mq(narrow) {
      margin-top: rem(22);
    }
  }

}