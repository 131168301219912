@use 'style/_variable' as *;
@use 'sass:math';

.organizationLogo {
  display: block;

  @include mq(narrow) {
    @include rect(rem(86), rem(60));
  }

  @include mq(wide) {
    @include rect(rem(90), rem(19.5));
  }

  &.--default {
    transition: $t_opening;
  }
}

.image {
  @include mq(narrow) {
    @include rect(rem(60), rem(13));

    display: block;
    margin: rem(16) rem(15) rem(22) rem(16);
  }
}

.imageArea {
  display: block;
  aspect-ratio: math.div(240,52);
  overflow: hidden; // Androidでリンクをタップしたとき、リンクエリア外にハイライトがはみ出して見えるのを防ぐ

  // "100BANCH"は、画像化した文字のマスク
  color: transparent;
  mask: url('./images/logo_100banch.webp') no-repeat center / contain;

  @include mq(narrow) {
    @include rect(rem(60), rem(13));

    margin: rem(16) rem(15) rem(22) rem(16);
  }

  @include mq(wide) {
    @include rect(rem(90), rem(19.5));
  }

  .--dark & {
    background-color: $c_lightness;
    backdrop-filter: brightness(2);
  }

  .--light & {
    background-color: $c_darkness;
  }

}