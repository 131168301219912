@use "style/_variable" as *;

.introduction {
  position: relative;
}

.mainVisual {
  position: relative;
  overflow: hidden;

  @include mq(narrow) {
    height: rem(192);
  }

  @include mq(wide) {
    height: rem(460);
    padding: rem(32);
    margin: 0 0 0 rem(16 + 326 + 66);
    background-color: $c_bg;
  }
}

// 前景画像
.figure {
  position: relative;
  width: 100%;
  margin: 0 auto;
  clip-path: polygon(0 0, 100% 0, 100% 87.8%, 83.6% 100%, 0 100%);

  @include mq(narrow) {
    @include rect(rem(256), rem(192));
  }

  @include mq(wide) {
    @include rect(rem(527), rem(395));
  }
}

.image {
  @include rect(100%, 100%);

  object-fit: cover;
}

// 背景画像
.background {
  $size: 0 - 15;

  position: absolute;
  top: rem($size);
  right: rem($size);
  bottom: rem($size);
  left: rem($size);
  display: block;
  margin: auto;

  @include mq(wide) {
   // 数値どおりにすると下に1pxほど隙間ができるため、下辺だけわざと1px多く切り詰めている
   clip-path: inset(rem(32 + 15) rem(32 + 15) rem(32 + 16));
  }
}

.backgroundImage {
  @include rect(100%, 100%);

  filter: blur(rem(15));
  object-fit: cover;
}

// title
.title {

  @include tracking(20);

  display: inline-block;
  padding: rem(0) rem(8) rem(1);
  font-weight: bold;
  line-height: 1.3;
  color: $c_lightness;
  background-color: $c_subtext;

  @include mq(narrow) {
    font-size: rem(20);
  }

  @include mq(wide) {
    font-size: rem(32);
  }
}

.body {
  @include mq(narrow) {
    padding: rem(40) rem(16) 0;
  }
  @include mq(wide) {
    @include tmp_container_wide;

    margin: rem(52) 0 0;
  }
}

// サブタイトル
.introductionTitle {
  line-height: 1.3;

  @include mq(narrow) {
    margin: rem(26 - (17 * 0.5)) 0 0;
  }

  @include mq(wide) {
    margin: rem(35) 0 0;
  }
}

.introductionTitleText {

  @include tracking(20);

  display: inline;
  font-weight: bold;
  line-height: 2.2;
  background: repeating-linear-gradient(transparent, transparent rem(5), $c_border rem(5), $c_border rem(5 + 5)) 0 100% / 100% rem(2) no-repeat;
  border-bottom: dashed 2px $c_border;

  @include mq(narrow) {
    font-size: rem(17);
  }

  @include mq(wide) {
    font-size: rem(20);
  }
}

// 紹介文
.description {
  @include tracking(90);

  font-weight: 500;
  line-height: 2;

  @include mq(narrow) {
    margin: rem(20) 0 0;
    font-size: rem(16);
  }

  @include mq(wide) {
    margin: rem(20) 0 0;
    font-size: rem(16);
  }
}