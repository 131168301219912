/**
* fit
* @description 親要素と同じ範囲の絶対配置をショートカットで指定
* @example
*  .box {
*    @include fit;
*  }
*/
@mixin fit() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
