@use "style/_variable" as *;

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;

  @include mq(narrow) {
    gap: rem(12);
    margin: rem(32) auto;
  }
  @include mq(wide) {
    gap: rem(16);
    margin: rem(48) auto;
  }
}


.page {
  position: relative;
  padding: 0;
  font-family: $f_en_bold;
  font-weight: normal;
  color: $c_darkness;
  white-space: nowrap;
  background: transparent;
  background-color: $c_lightness;
  border: none;
  border-radius: 50%;
  box-shadow: inset 0 0 0 rem(1) $c_border;

  @include mq(narrow) {
    @include rect(rem(52));

    padding-top: 0.2em;
    font-size: rem(16);
  }

  @include mq(wide) {
    @include rect(rem(60));

    padding-top: 0.3em;
    font-size: rem(18);

    // Windowsはフォント上下のスペースが大きいので位置調整
    [data-is-windows="true"] & {
      padding-top: 0.2em;
    }
  }

  @media (any-hover: hover) {
    &:not(:disabled) {
      cursor: pointer;

      &:hover {
        box-shadow: inset 0 0 0 rem(2) $c_darkness;
        transition: box-shadow $t_ease;
      }
    }
  }

  &.--near {
    @include mq(narrow) {
      display: none;
    }
  }

  // 選択中
  &[aria-checked] {
    color: $c_darkness;
    box-shadow: inset 0 0 0 rem(2) $c_darkness;
  }

  &.--more {
    top: -0.2em;
    width: 1em;
    padding: 0;
    color: $c_darkness;
    pointer-events: none;
    background-color: transparent;
    box-shadow: none;

    @include mq(wide) {
      font-size: rem(22);
    }
  }

  &.--remainder {
    background-color: transparent;

    @include mq(narrow) {
      width: 1em;
      padding: 0;
      color: transparent;
      pointer-events: none;
      box-shadow: none;
    }

    @include mq(wide) {
      pointer-events: auto;
      transition: none;
    }

    &::before {
      display: inline-block;
      margin: -0.175em auto 0;
      color: #000;
      vertical-align: text-bottom;
      content: '…';

      @include mq(wide) {
        font-size: rem(22);
        content: none;
      }
    }
  }
}

.preview,
.next {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  font-size: rem(20);
  background-color: transparent;
  border: none;
  transition: color $t_ease;

  @include mq(narrow) {
    @include rect(rem(7 + 7 + 7));
  }

  @include mq(wide) {
    @include rect(rem(8 + 12 + 12));
  }

  @media (any-hover: hover) {
    cursor: pointer;

    &:hover {
      color: rgba(#000, 0.5);
    }
  }

}

.preview {

  @include mq(narrow) {
    margin-right: rem(8);
  }


  &:disabled {
    display: none;
  }
}

.next {

  @include mq(narrow) {
    margin-left: rem(8);
  }

  &:disabled {
    display: none;
  }
}

.arrow {

  @include mq(narrow) {
    @include rect(rem(7), rem(12));
  }

  @include mq(wide) {
    @include rect(rem(8), rem(14));
  }
}