@use "style/_variable" as *;

.item {
  position: relative;
  margin: 0 0 rem(24);
  color: currentColor;
  text-decoration: none;
  border-bottom: 1px dashed $c_border;

  @include mq(narrow) {
    display: block;
    padding: 0 0 rem(24);
  }

  @include mq(wide) {
    display: grid;
    grid-template-rows: rem(16) auto;
    grid-template-columns: 1fr rem(269);
    gap: rem(15) rem(32);
    padding: 0 0 rem(24) 0;
  }

  // circle
  &::after {
    @include rect(rem(12));

    position: absolute;
    top: rem(2);
    right: 100%;
    display: block;
    content: "";
    background-color: $c_bg;
    border: rem(4) solid $c_darkness;
    border-radius: 50%;

    @include mq(narrow) {
      margin: auto rem(9);
    }

    @include mq(wide) {
      margin: auto rem(28);
    }
  }

  // line
  &::before {
    position: absolute;
    top: rem(26 - 16 + 2);
    right: 100%;
    width: 2px;
    height: calc(100% + rem(16));
    content: '';
    background-color: $c_border;

    @include mq(narrow) {
      margin: 0 rem(14);
    }

    @include mq(wide) {
      margin: 0 rem(33);

    }
  }

  // 最後の要素には線を表示しない
  &:last-child::before {
    display: none;
  }
}

.header {
  display: flex;
  gap: rem(16);
  align-items: center;
  height: rem(16);

  @include mq(narrow) {
    margin-bottom: rem(12);
  }

  @include mq(wide) {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
}

.date {
  position: relative;
  top: 0.175em; // Universe bold が下寄りなのでベースライン調整
  font-family: $f_en_bold;

  @include mq(narrow) {
    font-size: rem(15);
  }

  @include mq(wide) {
    font-size: rem(16);
  }
}

.type {
  @include tracking(20);

  height: rem(16);
  font-family: $f_en_bold;
  line-height: 1;
  color: $c_lightness;
  background-color: $c_darkness;

  @include mq(narrow) {
    padding: 0.15em rem(3) 0;
    font-size: rem(15);
  }

  @include mq(wide) {
    padding: 0.125em rem(3) 0;
    font-size: rem(16);

    // Windowsはフォント上下のスペースが大きいので位置調整
    [data-is-windows="true"] & {
      padding: 0 rem(3);
    }
  }
}

.title {
  font-weight: bold;

  @include mq(narrow) {
    overflow: hidden;
  }

  @include mq(wide) {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }

  span {
    @include tracking(40);
    @include tmp_inline-link;

    @include mq(narrow) {
      font-size: rem(18);
    }

    @include mq(wide) {
      font-size: rem(18);
    }

    @media (any-hover: hover) {
      .item:hover & {
        @include tmp_inline-link-hover-animation;
      }
    }
  }
}

// 画像
.figure {

  @include rect(rem(269), rem(151));

  position: relative;
  display: block;
  clip-path: polygon(0 0, 100% 0, 100% 87.4%, 87.4% 100%, 0 100%);

  @include mq(narrow) {
    position: relative;
    margin: rem(16) auto 0;
  }

  @include mq(wide) {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    margin: 0 auto;
  }

  @media (any-hover: hover) {
    &::after {
      @include tmp_stripe;

      content: '';
      opacity: 0;
      transition: opacity $t_ease;
    }
  
    .item:hover &::after {
      opacity: 1;
    }
  }

  &.--magazine {
    @include rect(rem(183), rem(245));

    clip-path: polygon(0 0, 100% 0, 100% 87.3%, 70% 100%, 0 100%);

    @include mq(wide) {
      margin: rem(16) auto 0;
    }
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}