@use "style/_variable" as *;

.update {
  @include mq(narrow) {
    @include tmp_container_narrow;

    margin-bottom: rem(32);
  }
  @include mq(wide) {
    @include tmp_container_wide;

    margin-bottom: rem(80);
  }

  // 子要素のmargin-bottomを相殺させず加算する
  &::after {
    display: table;
    content: '';
  }
}


.read {
  @include mq(narrow) {
    margin: rem(36) 0 rem(48);
  }
  @include mq(wide) {
    margin: rem(48) 0 rem(90);
  }
}

.paragraph {
  @include tracking(30);
  
  font-weight: 500;
  line-height: 2;

  @include mq(wide) {
    font-size: rem(15);
  }
}

.list {
  display: flex;
  flex-direction: column;

  @include mq(narrow) {
    gap: rem(16);
  }
  @include mq(wide) {
    gap: rem(40);
  }
}