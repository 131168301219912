@use "style/_variable" as *;

.introduction {
  position: relative;

  @include mq(wide) {
    padding: rem(25) rem(14) rem(20);
    margin: rem(35) 0 0;
    backdrop-filter: blur(rem(50)) brightness(1.3);
    background-color: #ffffffb3;
  }
}

.header {

  @include mq(narrow) {
    padding: rem(12) rem(6);
    background-color: #ffffffb3;
  }
}


// no keyword
.layout {
  display: flex;
  align-items: center;

  @include mq(narrow) {
    gap: rem(16);
  }

  @include mq(wide) {
    gap: rem(30);
  }
}


// No {no}
.no {
  @include tracking(40);

  position: relative;
  clip-path: polygon(0 0, 0 60%, 25% 100%, 100% 100%, 100% 40%, 75% 0, 0 0);
  font-family: $f_en_bold;
  color: $c_darkness;
  background: $c_bg_number;

  @include mq(narrow) {
    @include rect(rem(60), rem(24));

    padding: rem(1) 0 0 rem(30);
    font-size: rem(18);
  }

  @include mq(wide) {
    @include rect(rem(90), rem(36));

    padding: rem(1) 0 0 rem(46);
    font-size: rem(27);

    // Windowsはフォント上下のスペースが大きいので位置調整
    [data-is-windows="true"] & {
      padding: 0 0 0 rem(46);
    }
  }
}

.noUnit {
  position: absolute;

  @include mq(narrow) {
    @include rect(rem(9.6), rem(6.6));

    bottom: rem(6);
    left: rem(17.5);
  }
  @include mq(wide) {
    @include rect(rem(15), rem(10.3));

    bottom: rem(7);
    left: rem(26);
  }
}

// 人 ✕ {keyword}
.keyword {
  @include tracking(40);

  display: inline-flex;
  gap: 0.66em;
  align-items: center;
  padding: 0 1.25em;
  font-weight: bold;
  line-height: 1;
  border-radius: rem(999);

  @include mq(narrow) {
    height: rem(34);
    font-size: rem(12);
    border: 1px solid $c_subtext;
  }

  @include mq(wide) {
    height: rem(54);
    font-size: rem(19);
    border: 1px solid $c_darkness;
  }
}

.human {
  position: relative;

  @include mq(narrow) {
    @include rect(rem(14), rem(13));

    font-size: rem(13);
    font-weight: bold;
  }
  @include mq(wide) {
    @include rect(rem(18), rem(17));

    width: rem(20);
    font-size: rem(66);
  }
}

.textImage {
  position: absolute;

  @include mq(narrow) {
    top: 0.025em;
  }

  @include mq(wide) {
    top: 0;
  }

  path {
    stroke-width: 3;
  }
}

.corroborate {
  font-weight: normal;

  @include mq(narrow) {
    font-size: rem(12);
  }

  @include mq(wide) {
    font-size: rem(16);
  }
}

// メインコピー
.title {

  @include mq(narrow) {
    margin: rem(10) 0 0;
  }

  @include mq(wide) {
    margin: rem(20) 0 0;
  }
}

// 本文
.paragraph {
  @include tracking(30);

  font-weight: 500;
  line-height: 2;

  @include mq(narrow) {
    margin: rem(12) 0 0;
    font-size: rem(14);
  }
  @include mq(wide) {
    margin: rem(40 - 8) rem(52 - 14) 0;
    font-size: rem(15)
  }
}