@use "style/_variable" as *;

.layoutFooter {
  position: relative; // 13の未来詳細でfixedしたLayoutHeaderの透けを防ぐ
  color: $c_lightness;
  background-color: $c_darkness;
  transition: opacity $t_transition_enter;

  @include mq(narrow) {
    padding: rem(10) rem(15);
  }

  @include mq(wide) {
    display: grid;
    grid-template-columns: 1fr rem(250);
    gap: 0 rem(200);
    width: calc(100% - rem(16 + 326 + 66));
    padding: 0 rem(56) rem(72);
    margin: auto 0 0 auto;

  }
}

// タイトル
.title {
  @include rect(rem(250), rem(107));

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: rem(250);

  @include mq(narrow) {
    margin: rem(56) auto 0;
  }
  @include mq(wide) {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    margin: rem(78) 0 0;
  }
}

// SNS
.sns {
  @include rect(rem(250), rem(48));

  margin: rem(32) auto 0;

  @include mq(wide) {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
}

// カテゴリーナビゲーション
.categoryNavigation {
  @include mq(narrow) {
    margin: rem(48) 0 rem(66);
  }
  @include mq(wide) {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
    width: rem(460);
    margin: rem(72) 0 0;
  }

  // Windowsはフォント上下のスペースが大きいので位置調整
  [data-is-windows="true"] & {
    [class*="CategoryNavigation_link"] {
      &,
      &::before {
        background-position-y: rem(1);
      }
    }
  }

}


// WEB GLトランジションアニメーション待機
body[data-artwork-delay] .layoutFooter {
  opacity: 0;
}