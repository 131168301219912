@use "style/_variable" as *;

.report {
  position: relative;
  z-index: 1;

  @include mq(narrow) {
    article ~ & {
      margin: rem(90) 0 0;
    }
  }
  @include mq(wide) {
    @include tmp_container_wide;

    article ~ & {
      margin: rem(160) 0 0;
    }
  }
}

.header {
  @include mq(narrow) {
    @include tmp_container_narrow;
  }
}

.list {

  @include mq(narrow) {
    display: flex;
    gap: rem(24 - 15);
    max-width: min(100vw, 100%);
    padding: 0 0 rem(15);
    margin: rem(12) 0 rem(15);
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
  }

  @include mq(wide) {
    display: grid;
    // スクロールバー常時表示のとき左右余白を詰めて横スクロールを抑止する
    grid-template-columns: repeat(auto-fit, rem(302));
    gap: rem(80) 0;
    justify-content: space-between;
    margin: rem(25) 0 0;
  }

  // 13の未来詳細のモバイルレイアウトのみ変則スタイルを上書き
  > [class*='reportItem'] {
    @include mq(narrow) {
      width: rem(274 + 15);
      min-width: rem(274 + 15);
      padding-left: rem(15);
      margin: 0;
      background: linear-gradient($c_bg, $c_bg) no-repeat left #{rem(15)} top / rem(274) 100%;
      scroll-snap-align: start;
      box-shadow: none;

      &::before {
        @include tmp_card-shadow;

        position: absolute;
        top: 0;
        bottom: 0;
        left: rem(15);
        display: block;
        width: rem(274);
        content: '';
      }

      &:last-child {
        width: rem(274 + 15 + 15);
        min-width: rem(274 + 15 + 15);
        padding: 0 rem(15);
      }

      [class*='figure'] {
        @include rect(rem(254), rem(143));

        position: relative;
        z-index: 1;
      }

      [class*='header'],
      [class*='title'] {
        position: relative;
        z-index: 1;
      }
    }
  }
}