@use "style/_variable" as *;

.article {
  @include mq(narrow) {
    @include tmp_container_narrow;
  }
  @include mq(wide) {
    @include tmp_container_wide;

    display: grid;
    grid-template-columns: rem(260) 1fr;
    gap: rem(42);
  }

  ~.article {

    @include mq(narrow) {
      margin: rem(84) 0 0;
    }
    @include mq(wide) {
      margin: rem(144) 0 0;
    }
  }
}


.title {
  @include tracking(40);

  @include mq(narrow) {
    font-size:  rem(26);
  }
  @include mq(wide) {
    font-size:  rem(28);
  }
}

.body {
  position: relative;

  @include mq(narrow) {
    margin: rem(64) 0 0;
  }
  @include mq(wide) {
    margin: rem(64) 0 0;
  }

  &::before {
    @include rect(rem(50), rem(3));

    position: absolute;
    left: 0;
    display: block;
    content: '';
    background-color: $c_bg_number;
    border-radius: 999em;
    
    @include mq(narrow) {
      top: rem(-32);
    }

    @include mq(wide) {
      top: rem(-53);
    }
  }
}