@use 'style/_variable' as *;

.closeUpCursor {
  pointer-events: none;

  @media (any-hover: hover) {
    $position: 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
    $visible: cubic-bezier(0.175, 0.885, 0.32, 1.275);

    @include rect(rem(140));

    position: fixed;
    z-index: 2;
    margin: rem(-70) 0 0 rem(-70);
    overflow: visible;
    background-color: rgb(0 0 0 / 20%);
    filter: drop-shadow(3px 3px 16px rgb(0 0 0 / 8%));
    border-radius: 50%;
    opacity: 0;
    transition:
      opacity 250ms $visible,
      transform 250ms $visible,
      top $position,
      left $position;
    transform: scale(0);
    backdrop-filter: blur(rem(5)) brightness(0.7);
    will-change: top left;
  }

  // リンクにホバーしたときのみ表示
  [class*="Futures_futures"]:has([class*="IndexItem_link"]:hover) & {
    opacity: 1;
    transform: scale(1);
  }
}
