@use 'sass:math';
@use '../function/rem' as *;
@use '../mixin/mq' as *;
@use '../mixin/tracking' as *;
@use '../color' as *;

// 共通管理するCSSアニメーションkeyframes

// フェードイン
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}


// 下線アニメーション
@keyframes hoverLineLink {
  0% {
    background-position-x: right;
    background-size: 100% 1px;
  }

  50% {
    background-position-x: right;
    background-size: 0 1px;
  }

  51% {
    background-position-x: left;
    background-size: 0 1px;
  }

  to {
    background-position-x: left;
    background-size: 100% 1px;
  }
}