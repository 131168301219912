$c_darkness: #000;
$c_dark: #000;
$c_lightness: #fff;
$c_light: #fff;

$c_bg: #f5f5f5;
$c_bg_number: #e7e7e7;

$c_border: #ccc;

$c_highlighter: #a5a5a5;
$c_subtext: #888;
