@use "style/_variable" as *;

.magazineItem {
  scroll-snap-align: start;
  display: block;
  color: currentColor;
  text-decoration: none;

  @include mq(narrow) {
    width: rem(300 + 15);
    padding-left: rem(15);
  }

  @include mq(wide) {
    width: rem(300);
  }


    &:last-child {
      @include mq(narrow) {
        width: rem(300 + 15 + 15);
        padding: 0 rem(15);
      }
      @include mq(wide) {
        width: rem(300 + 32);
        padding: 0 rem(32) 0 0;
      }
    }
}

// header
.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: rem(300);
  padding: rem(45) 0 rem(45) rem(25);
  color: $c_lightness;

  @media (any-hover: hover) {
    &::after {
      @include tmp_stripe($m: null);

      top: rem(45 + 16);
      right: rem(16);
      bottom: rem(45 + 16);
      display: block;
      width: rem(225 - 16 - 16);
      content: '';
      opacity: 0;
      transition: opacity $t_ease;
    }

    .magazineItem:hover &::after {
      opacity: 1;
    }
  }

  &::before {
    @include tmp_card-shadow;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    display: block;
    width: rem(283);
    content: '';
    background-color: $c_darkness;
  }

}

// 画像
.figure {
  @include rect(rem(225), rem(300));

  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 87.3%, 70% 100%, 0 100%);
}

.image {
  @include rect(100%);

  object-fit: cover;
}

// 日付
.date {
  position: relative;

  [class*="Date_monthYear"] {
    font-size: rem(11);
  }

  [class*="Date_day"] {
    font-size: rem(35);
    font-weight: normal;
    color: $c_darkness;

    &::before {
      -webkit-text-stroke: 2px $c_lightness;
    }
  }
}

// カテゴリ
.tagList {
  position: absolute;
  right: rem(30);
  bottom: rem(58);
  left: rem(30);
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  gap: rem(8);
  margin: rem(8) 0 0;
}

.tag {
  @include tracking(20);

  padding: rem(3) rem(6);
  font-size: rem(10);
  font-weight: bold;
  color: $c_lightness;
  background-color: $c_darkness;
}

// description
.description {
  @include tracking(40);

  width: rem(283);
  margin: rem(18) 0 0;
  font-size: rem(15);
  font-weight: bold;

  // 3点リーダで省略
  // stylelint-disable-next-line
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
}