@use "style/_variable" as *;

.updateItem {
  @include tmp_card-shadow;

  position: relative;
  display: block;
  color: currentColor;
  text-decoration: none;
  background-color: $c_bg;

  @include mq(narrow) {
    padding: rem(24) rem(15);
  }

  @include mq(wide) {
    padding: rem(32);
  }
}


// 日付とタイプ
.status {
  display: flex;
  gap: rem(16);
  align-items: center;
  height: rem(16);

  @include mq(narrow) {
    margin-bottom: rem(16);
  }

  @include mq(wide) {
    margin-bottom: rem(15);
  }

}

.date {
  position: relative;
  top: 0.175em; // Universe bold が下寄りなのでベースライン調整
  font-family: $f_en_bold;

  @include mq(narrow) {
    font-size: rem(16);
  }

  @include mq(wide) {
    font-size: rem(17);
  }
}

.type {
  @include tracking(20);

  display: inline-block;
  padding: rem(2.5) rem(6);
  font-weight: bold;
  line-height: 1.1;
  color: $c_lightness;
  background-color: $c_subtext;

  @include mq(narrow) {
    font-size: rem(12);
  }
  
  @include mq(wide) {
    font-size: rem(13);
  }
}

// タイトル
.title {
  @include tracking(40);

  text-align: justify;

  @include mq(narrow) {
    font-size: rem(18);
  }

  @include mq(wide) {
    font-size: rem(22);
  }

  @media (any-hover: hover) {
    @include tmp_inline-link;

    display: inline;
    background-position-x: right;
    background-size: 0 1px;
    transition: background-size $t_ease;

    .updateItem:hover & {
      background-position-x: left;
      background-size: 100% 1px;
    }
  }
}

// 別窓アイコン
.iconExternalLink {
  @include rect(rem(16));

  position: absolute;

  @include mq(narrow) {
    top: rem(21);
    right: rem(15);
  }
  @include mq(wide) {
    right: rem(16);
    bottom: rem(16);
    color: currentColor;
    vertical-align: top;
  }
}