@use "style/_variable" as *;

// domアニメーション実装ソースをコメントアウトで残しています

.pixelateEffect {
  @include fit;

  // display: grid;
  // grid-template-rows: repeat(10, 1fr);
  // grid-template-columns: repeat(20, 1fr);
}

// // アニメーション: 中盤から
// @keyframes toClearHalf {
//   0% {
//     backdrop-filter: blur(rem(50));
//   }

//   100% {
//     backdrop-filter: blur(rem(0));
//   }
// }

// // アニメーション
// @keyframes toClear {
//   0% {
//     background-color: #ffff;
//     // backdrop-filter: blur(rem(50));
//   }

//   // 50% {
//   //   background-color: #fff0;
//   //   backdrop-filter: blur(rem(50));
//   // }

//   100% {
//     background-color: #fff0;
//     // backdrop-filter: blur(rem(0));
//   }
// }

// .cell {
//   backdrop-filter: blur(1000px);
//   background-color: #ffff;
//   will-change: background-color;

//   [data-intersecting='true'] & {
//     &[data-param="0"] {
//       background-color: transparent;
//       animation: toClearHalf 75ms steps(2) forwards;
//     }

//     &[data-param="1"] {
//       animation: toClear 150ms steps(3) 50ms forwards;
//     }

//     &[data-param="2"] {
//       animation: toClear 150ms steps(3) 100ms forwards;
//     }

//     &[data-param="3"] {
//       animation: toClear 150ms steps(3) 150ms  forwards;
//     }
//   }
// }

.whiteScreen {
  [data-intersecting="false"] & {
    transition: opacity 100ms ease-out;
  }

  [data-intersecting="true"] & {
    opacity: 0;
  }
}

// モザイクの1コマ
// 20 x 10 を 1440 * 720 にフィットに引き伸ばしているので、原寸は 1 x 1です。
// 小数点の端数が出るディスプレイサイズのときスキマができないよう、0.005のstrokeでわずかに隣と重ねています
.cell {
  stroke: #fff;
  stroke-width: 0.005;
}

// ロジックは揃えつつ
// モバイルレイアウトだけアニメーション時間を長くしています
$narrow_duration: 150 * 1.2;
$narrow_delay_brightness: 75 * 1.2;
$narrow_delay_alpha: 50 * 1.2;

$wide_duration: 150;
$wide_delay_brightness: 75;
$wide_delay_alpha: 50;


[data-intersecting="true"] {
  .group {
    animation: fadeIn forwards reverse;

    @include mq(narrow) {
      animation-duration: #{$narrow_duration * 1.5}ms;
    }

    @include mq(wide) {
      animation-duration: #{$wide_duration * 1.5}ms;
    }
  }

  // group 1
  [id*="mask_alpha"] .group:nth-child(1) {
    @include mq(narrow) {
      animation-delay: #{$narrow_duration - $narrow_delay_alpha}ms;
    }
    @include mq(wide) {
      animation-delay: #{$wide_duration - $wide_delay_alpha}ms;
    }
  }


  // group 2
  [id*="mask_brightness"] .group:nth-child(2) {
    @include mq(narrow) {
      animation-delay: #{$narrow_delay_brightness * 1}ms;
    }
    @include mq(wide) {
      animation-delay: #{$wide_delay_brightness * 1}ms;
    }
  }

  [id*="mask_alpha"] .group:nth-child(2) {
    @include mq(narrow) {
      animation-delay: #{($narrow_delay_brightness * 1) + $narrow_duration - $narrow_delay_alpha}ms;
    }
    @include mq(wide) {
      animation-delay: #{($wide_delay_brightness * 1) + $wide_duration - $wide_delay_alpha}ms;
    }
  }


  // group 3
  [id*="mask_brightness"] .group:nth-child(3) {
    @include mq(narrow) {
      animation-delay: #{$narrow_delay_brightness * 2}ms;
    }
    @include mq(wide) {
      animation-delay: #{$wide_delay_brightness * 2}ms;
    }
  }

  [id*="mask_alpha"] .group:nth-child(3) {
    @include mq(narrow) {
      animation-delay: #{($narrow_delay_brightness * 2) + $narrow_duration - $narrow_delay_alpha}ms;
    }
    @include mq(wide) {
      animation-delay: #{($wide_delay_brightness * 2) + $wide_duration - $wide_delay_alpha}ms;
    }
  }


  // group 4
  [id*="mask_brightness"] .group:nth-child(4) {
    @include mq(narrow) {
      animation-delay: #{$narrow_delay_brightness * 3}ms;
    }
    @include mq(wide) {
      animation-delay: #{$wide_delay_brightness * 3}ms;
    }
  }

  [id*="mask_alpha"] .group:nth-child(4) {
    @include mq(narrow) {
      animation-delay: #{($narrow_delay_brightness * 3) + $narrow_duration - $narrow_delay_alpha}ms;
    }
    @include mq(wide) {
      animation-delay: #{($wide_delay_brightness * 3) + $wide_duration - $wide_delay_alpha}ms;
    }
  }

}