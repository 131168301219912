@use "style/_variable" as *;

.categoryNavigation {
  display: flex;
  padding: 0;
  margin: 0;

  @include mq(narrow) {
    flex-direction: column;
    gap: rem(30);
    align-items: center;
  }

  @include mq(wide) {
    flex-wrap: wrap;
    gap: rem(12) rem(32);
    padding: 0;
    margin: 0;

    &.--minimum {
      gap: rem(18) rem(40);
    }
  }
}

.item {
  position: relative;
  display: flex;
  list-style: none;

  @include mq(narrow) {
    font-size: rem(18);
    text-align: center;
  }
  @include mq(wide) {
    font-size: rem(16);

    .--minimum & {
      font-size: rem(18);
    }

    // 斜線のセパレータ
    &:not(:last-child)::after {
      @include rect(1px, 1em);

      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      display: block;
      margin: auto 0 auto 1em;
      content: '';
      background-color: $c_border;
      transform: rotate(60deg);
    }

    // ワイドスクリーン向けグローバルナビのレイアウトではTOPを表示させない
    .categoryNavigation:not(.--minimum) &:first-child {
      display: none;
    }
  }
}

.link {
  padding-top: 0.175em;
  font-family: $f_en_bold;
  text-decoration: none;

  @include mq(narrow) {
    line-height: rem(17);
    color: $c_lightness;
  }

  @include mq(wide) {
    height: 1em;
    padding: 1px 1px 0;
    line-height: 1;
    color: currentColor;
  }

  // ワイドスクリーン向けグローバルナビではカレントマーカーをつける
  @include mq(wide) {
    .categoryNavigation:not(.--minimum) &[aria-current] {
      &,
      &::before {
        background-size: 100% calc(1em - rem(2));
      }

      // 「13の未来の当たり前」のみ書体が違うため背景の帯サイズもカスタマイズ
      &[href*="/futures"] {
        &,
        &::before {
          background-position-y: rem(1);
          background-size: 100% calc(1em + rem(2));
        }
      }
    }
  }


  // hover: Linkの背景とbeforeのbackground-sizeが同時にアニメーションする
  @media (any-hover: hover) {
    // highlighterで帯の装飾
    @include tmp_highlighter;

    .--dark & {
      background-image: linear-gradient($c_darkness, $c_darkness);
    }

    .--light & {
      background-image: linear-gradient($c_lightness, $c_lightness);
    }
  }
  // Windowsはフォント上下のスペースが大きいので位置調整
  [data-is-windows="true"] & {
    &,
    &::before {
      background-position-y: rem(2);
    }
  }

  // 「13の未来の当たり前」のみ書体が違うため個別に太字指定
  &[href*="/futures"] {
    top: -0.1em;
    padding-top: 0;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: bold;

    @include mq(narrow) {
      font-size: rem(17);
    }
    @include mq(wide) {
      font-size: rem(15);

      .--minimum & {
        font-size: rem(17);
      }
    }

    // 背景の帯サイズもカスタマイズ
    &,
    &::before {
      background-position-y: rem(1);
      background-size: 0 calc(1em + rem(1));
    }

    &:hover,
    &:hover::before {
      background-size: 100% calc(1em + rem(1));
    }
  }

}

// 別窓アイコン
.iconExternalLink {
  position: relative;
  margin: 0 0 0 0.33em;

  @include mq(narrow) {
    @include rect(rem(15));

    top: 0.05em;
  }
  @include mq(wide) {
    @include rect(calc(1em - rem(4)));

    color: currentColor;
    vertical-align: top;

    // Windowsはフォント上下のスペースが大きいので位置調整
    [data-is-windows="true"] & {
      @include rect(calc(1em - rem(4.4)));
    }

  }
}