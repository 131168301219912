@use "style/_variable" as *;
@use "sass:math";

.about {
  position: relative;

  @include mq(narrow) {
    padding: rem(42) 0 0;
  }

  @include mq(wide) {
    padding: rem(84) 0 0;
  }
}

// パラグラフ
.paragraph {
  font-weight: 500;
  line-height: 2;

  @include mq(narrow) {
    @include tracking(30);

    font-size: rem(16);
  }

  @include mq(wide) {
    @include tracking(40);

    font-size: rem(18);
  }

  ~ .paragraph {
    margin-top: 1.5em;
  }
}

// マーカー文字
.highlighter {
  @include tracking(80);

  padding: 0 0.1em;
  font-weight: 500;
  font-feature-settings: "palt";
  color: $c_lightness;
  background: linear-gradient($c_subtext,$c_subtext) left bottom / 100% 1.25em no-repeat;
}


// バナー
.banner {
  position: relative;
  background-color: gray;

  @include mq(narrow) {
    padding: rem(25) 0 rem(26) rem(15);
    margin: rem(84) 0 0;
    background: linear-gradient( 30.5deg, transparent rem(31), $c_bg rem(32), $c_bg) no-repeat right center / rem(297) 100%;
  }

  @include mq(wide) {
    margin: 0 rem(32) 0 rem(16 + 326 + 66);
  }
}

.bannerLink {
  position: relative;
  display: grid;
  place-items: center;
  background: url('./images/img_futures.webp') center center / contain no-repeat;

  @include mq(narrow) {
    @include rect(rem(360),rem(180));
  }

  @include mq(wide) {
    width: 100%;
    aspect-ratio: math.div(2,1);
    margin: rem(200) 0 0;
  }
}

.bannerText {
  display: block;
  backdrop-filter: blur(rem(50)) brightness(1.3);

  @include mq(narrow) {
    @include rect(rem(126),rem(33));

  }

  @include mq(wide) {
    @include rect(rem(294),rem(77));

  }
}