@use "style/_variable" as *;

.FuturesList {
  position: relative;

  @include mq(wide) {
    padding: rem(28) rem(30) rem(40) rem(30);
    margin: rem(24) 0 0;

    &::before,
    &::after {
      @include fit;

      z-index: 0;
      content: "";
    }

    // 角を切り落としたドロップシャドウ
    &::before {
      background:
        linear-gradient(30.5deg, transparent rem(37.5), $c_darkness rem(38), $c_darkness calc(100% - rem(38)), transparent calc(100% - rem(37.5))) no-repeat;
      filter: blur(rem(1));
      opacity: 0.08;
      transform: translate(rem(-1), rem(5));
    }

    // 角を切り落とした fill & border
    &::after {
      background:
      linear-gradient($c_darkness, $c_darkness) no-repeat left top / calc(100% - rem(74)) rem(2),
      linear-gradient($c_darkness, $c_darkness) no-repeat right bottom / calc(100% - rem(74)) rem(2),
      linear-gradient($c_darkness, $c_darkness) no-repeat left top / 2.5px calc(100% - rem(44)),
      linear-gradient($c_darkness, $c_darkness) no-repeat right bottom / 2.5px calc(100% - rem(43.5)),
      linear-gradient(30.5deg, transparent rem(37.5), $c_darkness rem(38), $c_darkness rem(39.5), $c_lightness rem(40), $c_lightness calc(100% - rem(40)), $c_darkness calc(100% - rem(39.5)),$c_darkness calc(100% - rem(38)), transparent calc(100% - rem(37.5))) no-repeat;
    }

  }
}

.list {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: rem(18);
  padding: 0;
  margin: 0;
  list-style: none;

}

.link {
  display: flex;
  gap: rem(9);
  align-items: center;
  color: currentColor;
  text-decoration: none;

}

// 番号
.no {
  @include rect(rem(23), rem(13));
  @include tracking(10);

  position: relative;
  display: flex;
  align-items: center;
  padding: rem(3) rem(2) rem(1);
  overflow: hidden;
  font-family: $f_en_bold;
  font-size: rem(12);
  line-height: 1;
  color: $c_subtext;

  // 選択中
  @include mq(wide) {
    // highlighterで帯の装飾
    @include tmp_highlighter(rem(16), $c_lightness);

    background-position-y: rem(-1);

    .link[aria-current] & {
      color: $c_darkness;

      &,
      &::before {
        background-size: 100% rem(16);
      }
    }
  }

  // hover: Linkの背景とbeforeのbackground-sizeが同時にアニメーションする
  @media (any-hover: hover) {
    .link:hover &,
    .link:hover &::before {
      color: $c_darkness;
      background-position-x: left;
      background-size: 100% rem(16);
    }
  }

}

// 人 ✕ {keyword}
.keyword {
  @include tracking(40);

  display: inline-flex;
  gap: 0.66em;
  align-items: center;
  font-weight: bold;
  font-feature-settings: "palt";
  line-height: 1;


  @include mq(wide) {
    font-size: rem(17);
  }
}

.human {
  @include textStroke(attr(data-text), rem(2), $c_darkness);

  top: -0.05em;
  font-size: rem(16);
  font-weight: normal;
  color: $c_lightness;
}

.corroborate {
  font-size: rem(13);
  color: $c_subtext;
}