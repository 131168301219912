@use '../function/rem' as *;
@use '../function/vw' as *;

/**
* 矩形のショートカット生成
* @param {長さの値} $w - width
* @param {長さの値} $h - height
* @example
*   @include rect(100%); // 100%の正方形
*   @include rect(rem(24), rem(18)); // 24 * 18の矩形
*/
@mixin rect($w: null, $h: null) {
  @if $w != null and $h == null {
    $h: $w;
  }

  width: $w;
  height: $h;
}
