@use "style/_variable" as *;

// 本文
.body {
  @include fit;

  display: block;
  overflow-y: auto;

  @include mq(narrow) {
    // ヘッダーの高さの分だけ下にずらす
    padding: rem(60) 0 0;
  }

  @include mq(wide) {
    // フッターを画面底辺へ固定するためにgridを使う
    display: grid;
  }
}

// 斜線の背景
.stripeBackground {

  @include mq(narrow) {
    $color: #f5f5f580; // $c_bg opacity 0.5

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
    background:
    linear-gradient(
      -210.5deg,
      transparent calc(51vw + rem(0)),
      $color calc(51vw + rem(0)),
      $color calc(51vw + rem(0 + 10)),
      transparent calc(51vw + rem(0 + 10)),
      transparent calc(51vw + rem(0 + 10 + 28)),
      $color calc(51vw + rem(0 + 10 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72 + 28 + 10)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72 + 28 + 10)),
    ) no-repeat left top 25vmin / 100% 100% fixed;
  }

  @include mq(wide) {
    $color: #f5f5f54d; // $c_bg opacity 0.3

    position: fixed;
    top: 0;
    right: var(--scrollbar);
    bottom: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
    background:
    linear-gradient(
      -210.5deg,
      transparent calc(51vw + rem(0)),
      $color calc(51vw + rem(0)),
      $color calc(51vw + rem(0 + 10)),
      transparent calc(51vw + rem(0 + 10)),
      transparent calc(51vw + rem(0 + 10 + 28)),
      $color calc(51vw + rem(0 + 10 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72 + 28 + 10)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72 + 28 + 10)),
    ) no-repeat left top -10vh / 100% 110vh fixed;
  }

  &.--invisible {
    opacity: 0;
  }


  &[aria-hidden="true"] {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }

  // 13の未来詳細からその他への遷移でartworkGL前面へ被らないよう対策：非表示 → 表示 アニメーション開始前にdelayをはさむ
  &[aria-hidden="false"] {
    transition: opacity $t_ease 125ms;

    // TOPからの遷移はpageTitleとあわせてdelayを取り消す
    [data-from="index"] & {
      transition: opacity 125ms ease;
    }
  }
}

// WEB GLトランジションアニメーション待機
.page {
  $enter: 250;
  $exit: 250;

// transition
  position: relative;
  // 非表示→表示アニメーションの開始状態を設定
  &[aria-hidden].enter {
    opacity: 0;
  }

  // 非表示→表示アニメーションの終了状態を設定
  &[aria-hidden].enterActive {
    opacity: 1;
    transition: opacity #{$enter}ms ease;
  }

  // 表示→非表示アニメーションの開始状態を設定
  &[aria-hidden].exit {
    opacity: 1;
  }

  &[aria-hidden].exitActive {
    opacity: 0;
    // 一瞬レイアウトシフトしたotherのコンテンツが見えてしまうのを防ぐためtransitionさせない
    // transition: opacity #{$exit}ms ease;

    // TOPから離脱する時、otherから離脱するときはトランジションを無効化
    // &[data-type='index'],
    // &[data-type='other'] {
    //   transition: none;
    // }
  }

  // 背景色変化中か、通常トランジションか、TOP→13の未来詳細トランジションが実行中は.enterや.exitより優先して非表示にする
  &[aria-hidden="true"] {
    pointer-events: none;
    opacity: 0 !important;
  }

}

// タイトルの重なり順
.header {
  z-index: 6;
}

// 13の未来詳細のモバイルレイアウトだけ、ヘッダーを固定させ重なり順を本文より背面にする
.fixedHeader {
  @include mq(narrow) {
    position: fixed;
    z-index: 0;
  }
}

// 13の未来詳細のみ背景を白く塗りつぶしてイラストの透けを防ぐ
.futuresFooter {
  position: relative;
}

// コピーライトをオープニング完了後に可視化させる
.copyright {
  pointer-events: none;
  opacity: 0;

  &.--initialize {
    pointer-events: default;
    animation: fadeIn 300ms 500ms ease-out forwards;
  }
}

// TOPから他のページへ遷移するとき、ナビやページタイトルが一瞬だけ見えるのを防ぐ
.invisible {
  display: none;
}


// ナビ設計移行途中の仮スタイル
.globalNavigation {

  @include mq(wide) {
    z-index: 1;
    margin: rem(210) rem(16 + 16) 0;
  }

}