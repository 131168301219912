@use "style/_variable" as *;

.opening {
  @include fit;

  position: fixed;
}

// フェードイン ... センタリング
.visible{
  @include fit;

  display: grid;
  place-items: center;
}

// 移動 ... topとleft基準の絶対配置
.move{
  @include fit;
}

// ブラー背景
.screen {
  @include fit;

  position: fixed;
  right: var(--scrollbar);
  background-color: #00000026;

  &[aria-hidden] {
    transition: 125ms 1500ms $t_easeOutCirc;
  }

  &[aria-hidden="true"] {
    backdrop-filter: blur(0) brightness(1);
  }

  &[aria-hidden="false"] {
    backdrop-filter: blur(rem(9)) brightness(0.75);
  }
}

// FEロゴ
.webSiteName {

  @include mq(narrow) {
    @include rect(rem(296), rem(126.84));
  }

  @include mq(wide) {
    @include rect(rem(490), rem(209));
  }

  // アニメーション: フェードイン
  .visible & {
    opacity: 0;
    transition: opacity 500ms 0ms ease, transform 2500ms 0ms $t_easeOutCirc;
    transform: scale(0.5);
  }

  // アニメーション: 移動
  .move & {
    position: absolute;

    @include mq(narrow) {
      @include fit;
      @include rect(auto);

      display: block;
      aspect-ratio: auto;

      // ロゴ
      [class*="TitleLogo_titleLogo"] {
        position: absolute;
        transition: width $t_opening, height $t_opening, left $t_opening, top $t_opening;
      }

      // コピー
      [class*="Copy_copy"] {
        position: absolute;
        transition: width $t_opening, height $t_opening, left $t_opening, top $t_opening;
      }
    }

    @include mq(wide) {
      &,
      [class*="TitleLogo_titleLogo"],
      [class*="Copy_copy"] {
        transition: width $t_opening, height $t_opening, left $t_opening, top $t_opening;
      }
    }
  }

  // アニメーション: フェードイン enter
  .visible[aria-hidden="true"] & {
    opacity: 0;
    transform: scale(0.5);
  }

  // アニメーション: フェードイン reave
  .visible[aria-hidden="false"] & {
    opacity: 1;
    transform: scale(1);
  }


  // アニメーション: 移動 enter
  .move[aria-hidden="true"] & {

    @include mq(narrow) {
      // ロゴ
      [class*="TitleLogo_titleLogo"] {
        top: calc(50% - rem(126.84 * 0.5));
        left: rem(39.5);
        width: rem(296);
      }

      // コピー
      [class*="Copy_copy"] {
        $areaH: 126.84;
        $beforeObjH: 70.83;
        $myselfH: 40.64;
        @include rect(rem(265.69), rem(40.64));

        top: calc(50% - rem($areaH * 0.5) + rem($areaH - $myselfH));
        left: rem(39.5);
      }
    }

    @include mq(wide) {
      @include rect(rem(490), rem(209));

      top: calc(50% - rem(209 * 0.5));
      left: calc(50vw - rem(490 * 0.5));

      // ロゴ
      [class*="TitleLogo_titleLogo"] {
        @include rect(rem(490), rem(117));
      }

      // コピー
      [class*="Copy_copy"] {
        @include rect(rem(440), rem(67));
      }
    }
  }

  // アニメーション: 移動 reave
  .move[aria-hidden="false"] & {

    @include mq(narrow) {

      // ロゴ
      [class*="TitleLogo_titleLogo"] {
        top: rem(14);
        left: rem(86);
        width: rem(138.39);
      }

      // コピー
      [class*="Copy_copy"] {
        @include rect(rem(129.86), rem(20));

        top: rem(20);
        left: rem(231);
      }

    }

    @include mq(wide) {
      @include rect(rem(225), rem(96));

      top: rem(30 + 20 + 30);
      left: rem(16 + 16);

      // ロゴ
      [class*="TitleLogo_titleLogo"] {
        @include rect(rem(225), rem(54));
      }

      // コピー
      [class*="Copy_copy"] {
        @include rect(rem(202), rem(31));
      }
    }
  }

}

// 100BANCHロゴ
.organization {
  position: fixed;

  @include mq(narrow) {
    top: 0;
    left: 0;
  }

  @include mq(wide) {
    top: rem(30);
    left: rem(16 + 16);
  }


  // アニメーション
  .move & {
    transition: opacity $t_opening;
  }

  // enter
  .move[aria-hidden="true"] & {
    opacity: 0;
  }

  // reave
  .move[aria-hidden="false"] & {
    opacity: 1;
  }
}