@use 'sass:math';
@use '../function/rem' as *;
@use '../mixin/mq' as *;
@use '../mixin/tracking' as *;
@use '../color' as *;

// スタイルテンプレート: レイアウト関連

/**
* コンテンツ両端の汎用余白：モバイル用
* @document
*/
@mixin tmp_container_narrow() {
  @include mq(narrow) {
    padding: 0 rem(15);
  }
}

/**
* コンテンツ両端の汎用余白：ワイドスクリーン用
* @document
*/
@mixin tmp_container_wide() {
  @include mq(wide) {
    padding: 0 rem(32) 0 rem(16 + 326 + 66);
  }
}