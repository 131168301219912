@use 'style/_variable' as *;

.futuresArticle {
  position: relative;

  ~ .futuresArticle {
    @include mq(narrow) {
      margin: rem(82) 0 0;
    }
  
    @include mq(wide) {
      margin: rem(168) 0 0;
    }
  }
}

.link {
  display: block;

  // マウスストーカーが可視化されているときはカーソルを非表示にする
  @media (any-hover: hover) {
    cursor: none;
  }
}

.figure {
  position: relative;
}

.image {
  @include rect(100%);

  object-fit: cover;
}

.column {
  @include mq(narrow) {
    @include tmp_container_narrow;

    margin: rem(15) 0 0;
  }

  @include mq(wide) {
    display: grid;
    grid-template-columns: rem(492) 1fr;
    margin: rem(36) 0 0;
  }
}

// no keyword
.header {
  @include mq(narrow) {
    display: flex;
    gap: rem(16);
    align-items: center;
  }

  @include mq(wide) {
    margin: rem(8) 0 0 rem(120);
  }
}

// No {no}
.no {
  @include tracking(40);

  position: relative;
  clip-path: polygon(0 0, 0 60%, 25% 100%, 100% 100%, 100% 40%, 75% 0, 0 0);
  font-family: $f_en_bold;
  color: $c_darkness;
  background: $c_bg_number;

  @include mq(narrow) {
    @include rect(rem(60), rem(24));

    padding: rem(1) 0 0 rem(30);
    font-size: rem(18);
  }

  @include mq(wide) {
    @include rect(rem(90), rem(36));

    padding: rem(1) 0 0 rem(46);
    font-size: rem(27);

    // Windowsはフォント上下のスペースが大きいので位置調整
    [data-is-windows="true"] & {
      padding: 0 0 0 rem(46);
    }
  }
}

.noUnit {
  position: absolute;

  @include mq(narrow) {
    @include rect(rem(9.6), rem(6.6));

    bottom: rem(6);
    left: rem(17.5);
  }
  @include mq(wide) {
    @include rect(rem(15), rem(10.3));

    bottom: rem(7);
    left: rem(26);
  }
}

// 人 ✕ {keyword}
.keyword {
  @include tracking(40);

  display: inline-flex;
  gap: 0.66em;
  align-items: center;
  padding: 0 1.25em;
  font-weight: bold;
  line-height: 1;
  border: 1px solid $c_subtext;
  border-radius: rem(999);

  @include mq(narrow) {
    height: rem(34);
    font-size: rem(12);
  }

  @include mq(wide) {
    height: rem(54);
    margin-top: rem(14);
    font-size: rem(19);
  }
}

.human {
  @include textStroke(attr(data-text), rem(1.5), $c_darkness);

  font-weight: normal;
  color: $c_lightness;

  @include mq(narrow) {
    font-size: rem(13);
  }
  @include mq(wide) {
    font-size: rem(20);
  }
}

.corroborate {
  color: $c_darkness;

  @include mq(narrow) {
    font-size: rem(12);
  }

  @include mq(wide) {
    font-size: rem(16);
  }
}

// 本文
.body {
  @include tracking(80);

  font-weight: lighter;
  font-feature-settings: 'palt';
  line-height: 1.3; // 32 / 24
  letter-spacing: 0.16em;
  vertical-align: 1em;

  @include mq(narrow) {
    margin: rem(12) 0 0;
    font-size: rem(24);
  }

  @include mq(wide) {
    font-size: rem(47);
  }

  strong {
    position: relative;
    display: inline-flex;
    padding: 0 0.05em;
    font-weight: lighter;

    @include mq(narrow) {
      // highlighterで帯の装飾
      @include tmp_highlighter(rem(25), $c_lightness);

      &,
      &::before {
        background-size: 100% rem(25);
      }
    }

    @include mq(wide) {
      // highlighterで帯の装飾
      @include tmp_highlighter(rem(48), $c_lightness);

      &,
      &::before {
        background-size: 100% rem(48);
      }
    }

    // 背景の帯が文字の中心になるよう、ずらす
    &,
    &::before {
      background-position-y: 0.2em;
    }

    // 暗幕
    &::after {
      [data-intersecting] & {
        @include fit;

        display: block;
        pointer-events: none;
        content: '';
        background-color: $c_lightness;
        transition: clip-path $t_ease #{$t_duration * 2ms};
      }

      [data-intersecting='true'] & {
        clip-path: inset(0 0 0 100%); // 可視
      }

      [data-intersecting='false'] & {
        clip-path: inset(0); // 不可視
      }
    }
  }
}
