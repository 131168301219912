@use "style/_variable" as *;

.layoutPageTitle {
  display: flex;
  align-items: center;

  @include mq(narrow) {
    height: rem(140);
      background:
        linear-gradient(-31deg, transparent rem(35.5), $c_bg rem(36), $c_bg) no-repeat;
  }
  @include mq(wide) {
    @include tmp_container_wide;

    height: rem(245);
    background:
      linear-gradient(-31deg, transparent rem(65.5), $c_bg rem(66), $c_bg) no-repeat;
    background-position: top right;
    background-size: calc(100% - rem(16 + 326 + 66)) 100%;
  }

  &.--invisible {
    opacity: 0;
  }

  &[aria-hidden="true"] {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }

  // 13の未来詳細からその他への遷移でartworkGL前面へ被らないよう対策：非表示 → 表示 アニメーション開始前にdelayをはさむ
  &[aria-hidden="false"] {
    transition: opacity $t_ease 125ms;

    // TOPからの遷移はopacityをフェードするとmaskをスライドで解除するエフェクトが隠れるため例外としてdelayを取り消す
    [data-from="index"] & {
      transition: opacity 125ms ease;
    }

  }
}

.highlighter {
  position: relative;
  display: inline-flex;
  overflow: hidden;
  line-height: 1;
  color: $c_lightness;
  background-image: linear-gradient($c_darkness,$c_darkness);

  @include mq(narrow) {
    gap: rem(10);
    width: rem(320);
    height: rem(40);
    padding: 0 rem(10) 0 rem(5);
  }

  @include mq(wide) {
    @include rect(rem(400), rem(60));

    gap: rem(16);
    padding: 0 rem(8);
  }

}

// 暗幕

.backdrop {
  @include fit;

  display: block;
  clip-path: inset(0 0 0 100%); // 暗幕が右へスライドアウト
  content: '';
  background-color: $c_darkness;

  &[data-invisible="true"] {
    clip-path: inset(0); // 暗幕で覆っている
  }

  &[data-invisible="false"] {
    clip-path: inset(0 0 0 100%); // 暗幕が右へスライドアウト
    transition: clip-path $t_ease; // スライドアウトのときだけアニメーション
  }

}

.title {
  @include tracking(100);

  position: relative;
  font-family: $f_en_bold;
  font-weight: normal;

  @include mq(narrow) {
    top: 0.17em;
    font-size: rem(36);
  }
  @include mq(wide) {
    top: 0.15em;
    font-size: rem(56);

    // Windowsはフォント上下のスペースが大きいので位置調整
    [data-is-windows="true"] & {
      top: 0.075em;
    }
  }
}

.copy {
  @include tracking(20);

  position: relative;
  font-size: rem(12);
  font-weight: bold;

  @include mq(narrow) {
    top: rem(36 - 12 - 2);
  }
  @include mq(wide) {
    top: rem(56 - 12 - 5);
  }
}
