@use "style/_variable" as *;

.item {
  list-style: none;

  @include mq(narrow) {
    position: relative;
  }

  @include mq(wide) {
    display: grid;
    grid-template-columns: rem(140) 1fr;
    gap: rem(10) rem(30);
  }

  // 区切り線
  ~ .collaborates::before {
    
    @include mq(narrow) {
      position: absolute;
      right: 0;
      bottom: 100%;
      left: 0;
      margin: rem(23) auto;
      content: '';
      border-top: 1px dashed $c_border;
    }
  }
}

.figure {
  margin: 0;
  overflow: hidden;
  border-radius: 50%;

  @include mq(narrow) {
    @include rect(rem(90));

    position: absolute;
    top: 0;
    left: 0;
  }

  @include mq(wide) {
    @include rect(rem(140));
  }
}

.image {
  @include rect(100%);

  object-fit: cover;
}

.head {
  display: flex;

  @include mq(narrow) {
    flex-direction: column;
    gap: rem(6);
    align-items: flex-start;
    justify-content: center;
    min-height: rem(90);
    padding: 0 0 0 rem(90 + 22);
    font-size: rem(18);
  }

  @include mq(wide) {
    gap: rem(15);
    align-items: center;
  }
}

.name {
  @include tracking(20);

  @include mq(narrow) {
    font-size: rem(18);
  }

  @include mq(wide) {
    font-size: rem(20);
  }
}

.position {
  @include tracking(20);

  display: inline-flex;
  align-items: center;
  min-height: rem(17);
  padding: rem(1) rem(3);
  font-size: rem(11);
  font-weight: bold;
  line-height: 1;
  color: $c_lightness;
  background-color: $c_subtext;
}

.body {
  @include tracking(90);
  
  font-size: rem(13);
  font-weight: 500;
  line-height: 1.84;

  @include mq(narrow) {
    margin: rem(12) 0 0;
  }

  @include mq(wide) {
    margin: rem(10) 0 0;
  }
}

.relation {
  @include tracking(20);

  display: flex;
  flex-wrap: wrap;
  padding: 0;
  font-size: rem(15);

  @include mq(narrow) {
    flex-direction: column;
    gap: rem(10);
    margin: rem(15) 0 0;
  }

  @include mq(wide) {
    gap: rem(15);
    margin: rem(15) 0 0;
  }

}

.relationItem {
  position: relative;
  list-style: none;

  &::after {
    @include rect(rem(18), rem(13));

    display: inline-block;
    margin: auto rem(5);
    vertical-align: middle;
    content: '';
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 13'%3E%3Cpath fill='%23000' d='M17.6,2.5 16.2,2.1 16.2,2.1 9,0.2 8.7,1.6 14.8,3.3 0.2,11.7 0.9,13 15.5,4.5 13.9,10.6 15.3,11 17.2,3.9 17.2,3.9 z'/%3E%3C/svg%3E") center/contain no-repeat;
  }
}

.relationLink {
  @include tmp_inline-link(0.2em);

  padding: rem(1) 0;
  font-weight: bold;
  color: currentColor;
  text-decoration: none;

  @media (any-hover: hover) {
    &:hover {
      @include tmp_inline-link-hover-animation;
    }
  }

}