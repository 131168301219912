@use "style/_variable" as *;

.signal {
  position: relative;
  overflow: hidden;

  @include mq(narrow) {
    @include tmp_container_narrow;
  }

}

.header {
  position: relative;
  z-index: 1;
  @include mq(narrow) {
    margin-top: rem(92);
  }
  @include mq(wide) {
    @include tmp_container_wide;

    margin-top: rem(90);
  }
}

.list {
  position: relative;
  z-index: 1;
  display: grid;
  flex-wrap: wrap;
  gap: 1em;

  @include mq(narrow) {
    grid-template-columns: repeat(3, 1fr);
    gap: rem(6);
    margin: rem(20) 0 0;
  }

  @include mq(wide) {
    @include tmp_container_wide;

    grid-template-columns: repeat(6, 1fr);
    gap: rem(18);
    margin: rem(27) 0 0 rem(64);
  }

  ~ .list {
    display: none;
    transition: display 0 $t_duration;

    @include mq(narrow) {
      margin-top: rem(6);
    }

    @include mq(wide) {
      margin-top: rem(18);
    }

    .--isShowAll & {
      display: grid;
    }

    // 初期表示では優先以外の項目を非表示
    [class*='signalItem'] {
      opacity: 0;

      .--isShowAll & {
        animation: fadeIn #{$t_duration * 1ms} #{$t_duration * 1ms} ease forwards;
      }
    }
  }
}

// 表示件数変更UI
.footer {
  display: grid ;
  align-items: center;

  @include mq(narrow) {
    @include rect(100%, rem(68));

    grid-template-columns: 1fr rem(58) 1fr;
    gap: rem(22);
    margin: rem(28) 0 0;
  }

  @include mq(wide) {
    @include tmp_container_wide;
    @include rect(calc(100% - #{rem(64)}), rem(78));

    grid-template-columns: 1fr rem(78) 1fr;
    gap: rem(22);
    margin: rem(28) 0 0 rem(64);

  }

  &::before,
  &::after {
    display: block;
    width: 100%;
    content: '';
    border-top: 1px dashed $c_darkness;
  }
}

// ボタン
.showMoreButton {
  position: relative;
  padding: 0;
  color: $c_lightness;
  background-color: $c_darkness;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 0 rem(5) $c_border;
  transition: background-color $t_ease, color $t_ease, transform #{$t_duration * 1ms} #{$t_duration * 1ms} ease;

  @include mq(narrow) {
    @include rect(rem(58));

    font-size: rem(11);
  }

  @include mq(wide) {
    @include rect(rem(68));

    font-size: rem(15);
  }

  // オンマウス効果
  @media (any-hover: hover) {
    cursor: pointer;

    &:hover {
      color: $c_darkness;
      background-color: $c_lightness;
    }
  }

  &::before,
  &::after  {
    @include fit;

    display: block;
    width: 1em;
    height: 1px;
    margin: auto;
    content: '';
    background-color: currentColor;
    transition: background-color $t_ease, width  #{$t_duration * 1ms} #{$t_duration * 1ms} ease;
  }

  &::after {
    transform: rotate(90deg);
  }

  // 全件表示中
  .--isShowAll & {
    transform: rotate(90deg);

    &::before {
      width: 0;
    }
  }
}