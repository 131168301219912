@use "style/_variable" as *;
@use "sass:math";

.copy {
  @include rect(89.76%, 32.05%);

  display: block;
  aspect-ratio: math.div(513,79);
  overflow: hidden; // Androidでリンクをタップしたとき、リンクエリア外にハイライトがはみ出して見えるのを防ぐ

  // "GARAGE Programから見えてきた未来社会のすがた"は、画像化した文字のマスク
  color: transparent;
  mask: url('./images/txt_copy.svg') no-repeat center / contain;

  // 白背景のときは黒
  &.--light {
    background-color: $c_darkness;
  }
  // 黒背景のときは輝度フィルタ
  &.--dark {
    background-color: #ffffff60;
    backdrop-filter: brightness(2);
  }
}