@use "style/_variable" as *;

.iconExternalLink {
  display: inline-block;
  color: currentColor;

  path {
    fill: currentColor;
    transition: fill $t_ease;
  }
}
