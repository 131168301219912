@use "style/_variable" as *;
@use "sass:math";

// ロゴ2行を格納している矩形エリア
.titleLogo {
  position: relative;
  aspect-ratio: math.div(560, 134);

}

// "FUTURE EXPLORATIONS"は、画像化した文字
.image {
  position: relative;
  vertical-align: middle;
}

// ロゴ背景のマーカー
.bg {
  @include fit;

  .line {
    position: absolute;
    left: 0;
    height: 47%;

    // FUTURE -
    &:first-child {
      top: 0;
      width: 71.25%;
    }

    // EXPLORATIONS
    &:last-child {
      bottom: 0;
      width: 100%;
    }

    .--dark & {
      background-color: #ffffff26;
      backdrop-filter: blur(rem(30)) brightness(2);
    }

    .--light & {
      background-color: $c_highlighter;
    }
  }
}