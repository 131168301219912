@use 'style/_variable' as *;
@use 'sass:math';

.futuresDetail {
  backdrop-filter: blur(rem(36)) brightness(1.15);

  @include mq(narrow) {
    margin-top: rem(230);
  }
  @include mq(wide) {
    margin-top: rem(583);
  }

  // 子要素のmarginまで背景を塗る
  &::before,
  &::after {
    display: table;
    content: '';
  }
}

// イラスト背後の装飾
.rectangleDecoration {
  position: fixed;
  right: var(--scrollbar);

  @include mq(narrow) {
    @include rect(rem(297), rem(231));

    top: rem(60);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 88.75%, 100% 88.75%, 100% 11%, 0 11%);
    background: linear-gradient(30.5deg, transparent rem(63.5), $c_bg rem(64)) no-repeat;
  }

  @include mq(wide) {
    top: rem(0);
    width: calc((100% - rem(16 + 326 + 66 + 32) - var(--scrollbar)) * 0.56);
    aspect-ratio: math.div(560, 583);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 91.3%, 94.3% 91.3%, 94.3% 5.5%, 0 5.5%);
    background: linear-gradient(30.5deg, transparent rem(63.5), $c_bg rem(64)) no-repeat;
  }
}

// モバイルレイアウトのとき、本文の背後へ潜るfixedのヘッダーを設置している
.dummyHeader {
  @include mq(narrow) {
    position: fixed;
    z-index: 0;

    [class*='LayoutHeader_organization'] {
      position: fixed;
      z-index: 1;
    }
  }

  @include mq(wide) {
    display: none;
  }
}

.header {
  position: relative;
  z-index: 1;

  @include mq(narrow) {
    @include tmp_container_narrow;

    margin: rem(24) 0 0;
  }
  @include mq(wide) {
    @include tmp_container_wide;
  }
}

// 白背景
.bgLightness {
  position: relative;
  z-index: 1;
}

// blur画像の固定背景
.bgWrapper {
  position: relative;
  width: 100%;

  @include mq(narrow) {
    mask-image: linear-gradient(transparent, black rem(58));
  }

  @include mq(wide) {
    mask-image: linear-gradient(transparent, black rem(174));
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    display: block;
    pointer-events: none;
    content: '';
    background: linear-gradient($c_lightness, transparent 66.6%) no-repeat center / 100% 100%,
      linear-gradient($c_lightness, transparent 33.3%) no-repeat center / 100% 100%;

    @include mq(narrow) {
      @include rect(100%, rem(58));
    }
    @include mq(wide) {
      @include rect(100%, rem(174));
    }
  }

  &::after {
    @include rect(100%, 20vh);

    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    pointer-events: none;
    content: '';
    background: linear-gradient(transparent, $c_lightness) no-repeat center / 100% 100%;
  }
  // report,projectコンテンツが空のとき
  &.--isBeforeGarageEmpty {
    &::after {
      @include mq(narrow) {
        display: none;
      }
    }
  }
  // eventコンテンツが空のとき
  &.--isEventEmpty {
    &::after {
      // このボカシの高さ + marquee + garageProgramのmargin-bottom
      @include mq(narrow) {
        top: calc(-20vh + rem((54 + 133 + 54) - 90));
      }
      @include mq(wide) {
        bottom: calc(-20vh + rem((58 + 128 + 58) - 160));
      }
    }
  }
}

// 追従背景画像の余白調整
.bgLightnessSpacer {
  margin-top: -100vh;

  @include mq(narrow) {
    margin-top: calc(-120vh + rem(193));
  }
  @include mq(wide) {
    margin-top: calc(-120vh + rem(429));
  }

  .bgWrapper.--isBeforeGarageEmpty & {
    @include mq(narrow) {
      margin-top: calc(-120vh + rem(193));
    }
    @include mq(wide) {
      margin-top: calc(-120vh + rem(380));
    }
  }
}

.bg {
  @include rect(100%, 120vh);

  position: sticky;
  top: -10vh;
  z-index: 0;
  pointer-events: none;
  background-color: $c_lightness;
}

.bgImage {
  @include rect(100%);

  position: relative;
  object-fit: cover;
}

// 斜線の装飾
.stripe {
  $color: #f5f5f54d; // $c_bg opacity 0.3

  width: 100%;
  pointer-events: none;
  background: linear-gradient(
      -210.5deg,
      transparent calc(51vw + rem(0)),
      $color calc(51vw + rem(0)),
      $color calc(51vw + rem(0 + 10)),
      transparent calc(51vw + rem(0 + 10)),
      transparent calc(51vw + rem(0 + 10 + 28)),
      $color calc(51vw + rem(0 + 10 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72 + 28 + 10)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72 + 28 + 10))
    )
    no-repeat center / 100% 100%;

  @include mq(narrow) {
    height: rem(395);
  }
  @include mq(wide) {
    height: rem(1024);
  }

  &.afterHeader {
    position: absolute;
    z-index: 0;

    @include mq(narrow) {
      display: none;
    }

    // 画面幅が広いときのみ表示
    @include mq(wide) {
      bottom: rem(-298 + -174 + -14);
      // スクロールバー常時表示のとき横スクロールを発生させないため100%を基準にする
      width: calc(100% - rem(16 + 326 + 66));
    }
  }

  &.beforeBgWrapper {
    position: absolute;
    z-index: 1;

    @include mq(narrow) {
      top: rem(12);
    }

    @include mq(wide) {
      top: rem(-298);
    }

    .bgWrapper.--isBeforeGarageEmpty & {
      @include mq(narrow) {
        display: none;
      }
    }
  }

  &.beforeGarageProgram {
    position: absolute;
    z-index: 1;

    @include mq(narrow) {
      margin: rem(-1 * (375 - 140)) 0 rem(-140) 0;
    }
    @include mq(wide) {
      margin: rem(-1 * (1024 - 640)) 0 rem(-640) 0;
    }

    &.--isBeforeGarageEmpty {
      @include mq(narrow) {
        margin: rem(-1 * (375 - 220)) 0 rem(-220) 0;
      }
      @include mq(wide) {
        display: none;
      }
    }
  }
}

// GARAGE Program背景
.garageProgram {
  position: relative;
  z-index: 2;
  overflow-x: hidden;

  @include mq(narrow) {
    min-height: rem(200 - 36);
    padding-bottom: rem(66);
    margin-top: rem(88);
    background: linear-gradient(
        -210.5deg,
        transparent rem(45),
        $c_bg_number rem(46),
        $c_bg_number calc(100% - rem(46)),
        transparent calc(100% - rem(45))
      )
      no-repeat left #{rem(24)} / 100% 100%;
  }

  @include mq(wide) {
    min-height: rem(306 + 30);
    padding-bottom: rem(48);
    margin-top: rem(130);
    background: linear-gradient(
        -210.5deg,
        transparent rem(128),
        $c_bg_number rem(129),
        $c_bg_number calc(100% - rem(129)),
        transparent calc(100% - rem(128))
      )
      no-repeat right top / rem(1000) 100%;
  }

  // 子要素のmargin-topまで背景画像を敷く
  &::before {
    display: table;
    content: '';
  }

  // 装飾文字 GARAGE Program
  &::after {
    @include tracking(120);

    position: absolute;
    z-index: 0;
    display: block;
    font-family: $f_en_bold;
    color: $c_lightness;
    text-shadow: 1px 1px $c_bg_number, 1px -1px $c_bg_number, -1px 1px $c_bg_number,
      -1px -1px $c_bg_number;
    white-space: nowrap;
    pointer-events: none;
    content: 'GARAGE Program';

    @include mq(narrow) {
      top: rem(-20 + -16);
      left: 0;
      font-size: rem(133);
    }

    @include mq(wide) {
      top: rem(-30);
      left: rem(756);
      font-size: rem(204);

      // Windowsはフォント上下のスペースが大きいので位置調整
      [data-is-windows='true'] & {
        top: rem(-48);
      }
    }
  }
}

// event空時のgarage余白調整
.garageWrap {
  .bgWrapper.--isEventEmpty & {
    @include mq(narrow) {
      padding-bottom: rem(90);
    }
    @include mq(wide) {
      padding-bottom: rem(160);
    }
  }
}
