// Breakpoint名の定義
$breakpoint: (
  narrow: 'screen and (max-width: 900px)',
  wide: 'screen and (min-width: 901px)',
);

/**
* Breakpointの設定
* @param {narrow | wide} $bp - Breakpointの名前
* @example
*   @include mq('narrow') { ... }
*   @include mq('wide') { ... }
*/
@mixin mq($bp) {
  @media #{map-get($breakpoint, $bp)} {
    @content;
  }
}