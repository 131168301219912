@use "style/_variable" as *;

.breadcrumbs {
  position: relative; // 13の未来詳細でfixedしたLayoutHeaderの透けを防ぐ
  display: flex;
  align-items: center;
  overflow: hidden;

  @include mq(narrow) {
    @include rect(100%, rem(52));

    gap: rem(8);
    padding: rem(10) rem(12);
    font-size: rem(11);
  }
  @include mq(wide) {
    @include rect(100%, rem(88));

    gap: rem(12);
    padding: 0 rem(28) 0 rem(16 + 326 + 66 + 28);
    margin: auto 0 0 auto;
    font-size: rem(12);
  }


  // 13の未来詳細のみ背景を白く塗りつぶしてイラストの透けを防ぐ
  &[class*='Layout_futuresFooter'] {
    background-color: $c_lightness;
  }
}

.link {
  @include tracking(20);

  position: relative;
  top: 0.1em;
  color: currentColor;
  text-decoration: none;
  white-space: nowrap;

  // カレントページ
  &:last-child {
    overflow: hidden;
    color: $c_subtext;
    text-overflow: ellipsis;
  }
}

.arrow {
  @include rect(rem(6), rem(10));
}

.top {
  display: block;

  @include mq(narrow) {
    @include rect(rem(13), rem(11));

    position: relative;
    top: rem(-1);
    transition: opacity $t_transition_enter;
  }
  @include mq(wide) {
    @include rect(rem(16), rem(14));
  }
}

// WEB GLトランジションアニメーション待機
body[data-artwork-delay] .breadcrumbs {
  opacity: 0;
}