@use "style/_variable" as *;

.layoutCopyright {
  @include mq(narrow) {
    position: relative;
    z-index: 1;
    padding: 0 rem(15) rem(10);
  }
  @include mq(wide) {
    position: fixed;
    top: 0;
    right: var(--scrollbar);
    bottom: 0;
    z-index: 1;
    display: grid;
    place-items: center;
    width: rem(30);
    text-align: center;
    writing-mode: vertical-rl;
  }

  &.--light {
    @include mq(narrow) {
      background-color: $c_darkness;
    }
  }
}

// コピーライト
.small {
  font-family: $f_en_bold;
  font-size: rem(10);
  color: $c_highlighter;
}