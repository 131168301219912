@use "style/_variable" as *;

.projectItem {
  @include tmp_card-shadow;

  color: currentColor;
  text-decoration: none;
  background-color: $c_bg;

  @include mq(narrow) {
    display: grid;
    grid-template-rows: rem(74) auto;
    grid-template-columns: rem(98) 1fr;
    gap: rem(16) rem(15);
    padding: rem(24) rem(15);
    background-color: $c_bg;
  }

  @include mq(wide) {
    position: relative;
    min-height: rem(135);
    padding: rem(32);
    padding-left: rem(180 + 26 + 32);
  }
}

.figure {
  @include mq(narrow) {
    @include rect(rem(98), rem(74));

    clip-path: polygon(0 0, 100% 0, 100% 87.8%, 83.6% 100%, 0 100%);
  }

  @include mq(wide) {
    @include rect(rem(180), rem(135));

    position: absolute;
    top: 0;
    left: 0;
    margin: rem(32);
    clip-path: polygon(0 0, 100% 0, 100% 87.8%, 83.6% 100%, 0 100%);
  }

  @media (any-hover: hover) {
    &::after {
      @include tmp_stripe($size: 81);

      content: '';
      opacity: 0;
      transition: opacity $t_ease;
    }

    .projectItem:hover &::after {
      opacity: 1;
    }
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  @include tracking(20);

  display: inline-block;
  font-weight: bold;
  line-height: 1.1;
  color: $c_lightness;
  background-color: $c_subtext;

  @include mq(narrow) {
    padding: rem(2) rem(4);
    font-size: rem(17);
  }
  
  @include mq(wide) {
    padding: 0 rem(6) rem(1);
    font-size: rem(20);
  }
}

.description {
  @include tracking(90);

  font-size: rem(14);
  font-weight: 500;
  line-height: 1.7;

  // 3点リーダで省略
  // stylelint-disable-next-line
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;

  @include mq(narrow) {
    grid-column: 1 / 3;
  }
  
  @include mq(wide) {
    margin: rem(12) 0 0;
  }
}

.futuresList {
  display: flex;
  flex-direction: column;
  gap: rem(4);
  padding: 0;
  margin: 0;

  @include mq(narrow) {
    grid-column: 1 / 3;
  }
  
  @include mq(wide) {
    margin: rem(12) 0 0;
  }
}