@use 'sass:math';
@use '../function/rem' as *;
@use '../mixin/mq' as *;
@use '../mixin/fit' as *;
@use '../color' as *;
@use '../transition' as *;
@use './keyframes' as *;

// スタイルテンプレート: 装飾関連

/**
* 文字を反転マーカー
* @document 文字の背景に反転色の帯をつける
* @param {Number} $h - 帯の高さ
* @param {Color} $bg - 帯の色
*/
@mixin tmp_highlighter($h: calc(1em - rem(2)), $bg: null) {
  position: relative;
  background-image: linear-gradient($bg, $bg);

    @media (any-hover: hover) {
      &:hover,
      &:hover::before {
        background-position-x: left;
        background-size: 100% $h;
      }
    }

  &::before {
    @include fit;

    z-index: 1;
    display: block;
    pointer-events: none;
    content: '';
    // フィルタで反転効果をかける
    background-image: linear-gradient($c_lightness, $c_lightness);
    mix-blend-mode: difference;
  }

  &,
  &::before {
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    background-size: 0 $h;
    transition: background-size $t_ease;
  }
}

/**
* ストライプフィルター
* @document 背景にストライプをつける
* @param {Number} $size - ストライプのbackground-size height
* @param {Number} $m - ストライプのマージン
*/
@mixin tmp_stripe($size: 90, $m: rem(16)) {
  position: absolute;
  top: $m;
  right: $m;
  bottom: $m;
  left: $m;
  display: block;
  pointer-events: none;
  background: linear-gradient(30deg, transparent 13.74%, $c_lightness 14.74%, $c_lightness 21.44%, transparent 22.44%, transparent 36.18%, $c_lightness 37.18%, $c_lightness 49%, transparent 50%, transparent 63.74%, $c_lightness 64.74%, $c_lightness 71.44%, transparent 72.44%, transparent 86.18%, $c_lightness 87.18%, $c_lightness 100%) repeat left top / rem($size * 1.733) rem($size);
  backdrop-filter: blur(rem(8)) brightness(1.15);
}

/**
* ストライプアニメーション
* @document 背景にストライプをつける
* @param {Number} $size - ストライプのbackground-size height
* @param {Number} $duration - ストライプのアニメーション時間
*/
@mixin tmp_stripe-animation($size: 90, $duration: 2500ms) {
  @keyframes move-stripe-thin-#{$size} {
    0% {
      background-position: 0 0;
    }
  
    100% {
      background-position: 0 rem($size);
    }
  }

  animation: move-stripe-thin-#{$size} $duration linear infinite reverse;
}

/**
* ドロップシャドウ
* @document リンクカードの共通ドロップシャドウ
*/
@mixin tmp_card-shadow() {
  box-shadow: 3px 3px 16px #0000000A;
}

/**
* インラインテキストの下線グラフィック
* @document hoverしたときアニメーションさせるためのグラフィック
* @param {Number} $bottom - 線が下端からどのくらいの距離で表示されるか
*/
@mixin tmp_inline-link($bottom: rem(0)) {
  background: linear-gradient($c_darkness, $c_darkness) left bottom #{$bottom} / 100% 1px no-repeat;
}

/**
* インラインテキストの下線アニメーション
* @document hoverしたときbackgroundに設定したgradientのサイズを変更するアニメーションを実行する
* @param {Number} $duration - アニメーション再生時間 / ミリ秒
*/
@mixin tmp_inline-link-hover-animation($duration: 800) {
  animation: hoverLineLink #{$duration}ms cubic-bezier(0.215, 0.61, 0.355, 1) 0ms;
}