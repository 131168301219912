@use "style/_variable" as *;

.projectLog {
  position: relative;
}

.body {
  background-color: $c_bg;

  @include mq(narrow) {
    padding: rem(16) rem(15) rem(30);
    margin: rem(24) rem(-15) 0;
  }

  @include mq(wide) {
    padding: rem(36) rem(69);
    margin: rem(30) 0 0;
  }

}

.list {
  @include mq(narrow) {
    padding-left: rem(20);
  }
  @include mq(wide) {
    position: relative;

    // line装飾
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      display: block;
      width: 2px;
      margin: 0 rem(33);
      content: '';
      background-color: $c_border;
    }
  }
}

.item {
  position: relative;

  // 最初の、プロジェクトスタート項目
  &.--start {
    @include tracking(20);

    font-weight: bold;
    background-color: $c_lightness;

    @include mq(narrow) {
      padding: rem(10);
      font-size: rem(14);
    }

    @include mq(wide) {
      display: flex;
      gap: rem(16);
      padding: rem(12) rem(24);
      font-size: rem(15);
    }

    // circle
    &::after {
      @include rect(rem(12));

      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      display: block;
      content: "";
      background-color: $c_bg;
      border: rem(4) solid $c_darkness;
      border-radius: 50%;

      @include mq(narrow) {
        margin: auto rem(9);
      }

      @include mq(wide) {
        margin: auto rem(28);
      }
    }

    // 1件しかないとき上下の余白をそろえる
    &:first-child {
      @include mq(narrow) {
        margin-top: rem(14);
      }
    }

    // line
    &:not(:first-child)::before {
      position: absolute;
      top: 0;
      right: 100%;
      width: 2px;
      height: 50%;
      content: '';
      background-color: $c_border;

      @include mq(narrow) {
        margin: 0 rem(14);
      }

      @include mq(wide) {
        margin: 0 rem(33);
      }
    }

    .name {
      @include tracking(20);

      display: inline-block;
      font-weight: bold;
      line-height: 1.1;
      color: $c_lightness;
      background-color: $c_subtext;

      @include mq(narrow) {
        padding: rem(2) rem(4);
        margin: rem(7) 0 0;
        font-size: rem(17);
      }

      @include mq(wide) {
        padding: 0 rem(6) rem(1);
        font-size: rem(20);
      }
    }
  }
}