@use "style/_variable" as *;

.magazine {
  position: relative;
  z-index: 1;

  @include mq(narrow) {
    padding: rem(62) 0 0;
  }
  @include mq(wide) {
    padding: rem(120) 0 0 rem(16 + 326 + 66);

  }

  &::after {
    display: table;
    content: '';
  }
}

// 見出し
.headline {
  @include mq(narrow) {
    height: rem(84);
    margin-left: rem(15);
  }

  @include mq(wide) {
    br {
      display: none;
    }
  }

}

// コントロールUI
.console {
  position: absolute;

  @include mq(narrow) {
    @include rect(0);

    top: 0;
    right: 0;
    overflow: hidden;
    pointer-events: none;
  }
  @include mq(wide) {
    position: absolute;
    top: rem(120);
    right: rem(32);
    display: flex;
    gap: rem(24);
    align-items: center;
    height: rem(42);

  }
}

.button {

  @include mq(wide) {
    @include rect(rem(64));

    padding: 0;
    background-color: rgba($c_subtext ,1);
    border: none;
    border-radius: 50%;
    transition: background-color $t_ease;
    
      &:disabled {
        background-color: rgba($c_subtext ,0.6);
      }
  }

  @media (any-hover: hover) {
    &:not(:disabled) {
      cursor: pointer;
    }

    &:hover {
      background-color: rgba($c_subtext ,0.6);
    }
  }
}

// カード一覧
.list {
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;

  @include mq(narrow) {
    gap: rem(24);
    max-width: 100vw;
    padding: 0 0 rem(36);
    margin: rem(12) 0 0;
  }

  @include mq(wide) {
    gap: rem(48);
    max-width: calc(100vw - rem(16 + 326 + 66) - var(--scrollbar));
    padding-bottom: rem(48);
    margin: rem(36) 0 0;
    scroll-behavior: smooth;
  }

  &::-webkit-scrollbar{
    height: rem(2);
  }

  &::-webkit-scrollbar-track{
    background-color: $c_border;
  }

  &::-webkit-scrollbar-thumb{
    background-color: $c_darkness;
  }

  &.--isIosSafari {
    background: linear-gradient($c_border,$c_border) no-repeat left bottom #{rem(4)} / 100% rem(2);
  }
}