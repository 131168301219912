/**
* @name textStroke
* @description 袋文字に加工する
* @param {String} $text - 袋文字にする文字列
* @param {Number} $stroke - フチの太さ
* @param {Color} $color - フチの色
* @example
*   .foo {
*     @include textStroke(attr(data-text), rem(3), #000);
*   }
*   <div class="foo" data-text="Hello World">Hello World</div>
*/
@mixin textStroke($text: "", $stroke, $color) {
  position: relative;
  display: inline-block;
  font-weight: bold;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: $text;
  }

  &::before {
    -webkit-text-stroke: $stroke #{$color};
    filter: blur(rem(0.1));
  }

  &::after {
    color: currentColor;
  }
}
