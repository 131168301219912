@use "style/_variable" as *;

.headline {
  display: flex;
  gap: rem(16);
  align-items: flex-end;

  @include mq(narrow) {
    height: rem(42);
  }
  @include mq(wide) {
    height: rem(55);
  }
}

.title {
  font-family: $f_en_bold;
  font-weight: normal;
  line-height: 1;

  @include mq(narrow) {
    font-size: rem(42);
  }
  @include mq(wide) {
    font-size: rem(55);
  }
}

.subTitle {
  @include tracking(20);

  position: relative;
  font-size: rem(12);
  font-weight: bold;

  @include mq(narrow) {
    top: rem(42 * 0.18 * -1); // iPhone Safariはディセンダーラインが低めにレンダリングされるので、気持ち程度下げる。
  }
  @include mq(wide) {
    top: rem(55 * 0.2 * -1);

    // Windowsはフォント上下のスペースが大きいので位置調整
    [data-is-windows="true"] & {
      top: rem(55 * 0.1 * -1);
    }
  }
}