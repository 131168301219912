@use "style/_variable" as *;

.project {
  position: relative;
  z-index: 1;

  @include mq(narrow) {
    @include tmp_container_narrow;
  }
  @include mq(wide) {
    @include tmp_container_wide;
  }
}

.body {
  display: flex;
  flex-direction: column;

  @include mq(narrow) {
    gap: rem(16);
    margin: rem(20) 0 0;
  }
  
  @include mq(wide) {
    @include tmp_card-shadow;

    gap: rem(30);
    padding: rem(32);
    margin: rem(26) 0 0;
    background-color: $c_bg;
  }

  // 13の未来詳細のみ変則スタイルを上書き
  > [class*='projectItem'] {

    @include mq(narrow) {
      @include tmp_card-shadow;
    }

    @include mq(wide) {
      padding: 0 0 0 rem(180 + 26);
      box-shadow: none;
    }

    [class*='figure'] {
      @include mq(wide) {
        margin: 0;
      }
    }

    [class*='futuresList'] {
      display: none;
    }
  }
}
