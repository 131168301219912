@use "style/_variable" as *;

.reportItem {
  @include tmp_card-shadow;

  position: relative;
  display: block;
  color: currentColor;
  text-decoration: none;
  background-color: $c_bg;

  @include mq(narrow) {
    width: rem(323);
    margin: 0 auto;
  }

  @include mq(wide) {
    width: rem(302);
  }
}


.figure {
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 87.3%, 87.1% 100%, 0 100%);

  @include mq(narrow) {
    @include rect(rem(323), rem(182));
  }

  @include mq(wide) {
    @include rect(rem(277), rem(156));
  }

  @media (any-hover: hover) {
    &::after {
      @include tmp_stripe;

      content: '';
      opacity: 0;
      transition: opacity $t_ease;
    }

    .reportItem:hover &::after {
      opacity: 1;
    }
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header {
  display: flex;
  gap: rem(14);
  padding: 0 rem(25);

  @include mq(narrow) {
    padding: rem(0) rem(25);
    margin: rem(24) 0 0;
  }

  @include mq(wide) {
    margin: rem(32) 0 0;
  }
}

// 日付
.date {
  [class*="Date_day"] {
    font-weight: normal;
    color: $c_bg;

    &::before {
      -webkit-text-stroke: rem(2.5) $c_darkness;
    }
  }
}

// プロジェクト
.project {
  display: flex;
  flex-wrap: wrap;
  gap: rem(5);
  align-content: flex-start;
}

.tag {
  @include tracking(20);

  display: inline-block;
  padding: rem(2) rem(6) rem(3);
  font-weight: bold;
  line-height: 1.1;
  color: $c_lightness;
  background-color: $c_subtext;

  @include mq(narrow) {
    font-size: rem(12);
  }
  
  @include mq(wide) {
    font-size: rem(13);
  }
}

// title
.title {
  @include tracking(40);

  display: block;
  padding: rem(0) rem(25) rem(25);
  font-weight: bold;

  // 3点リーダで省略
  // stylelint-disable-next-line
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;

  @include mq(narrow) {
    margin: rem(8) 0 0;
    font-size: rem(16);
  }

  @include mq(wide) {
    margin: rem(8) 0 0;
    font-size: rem(18);
  }

  span {
    // 3点リーダで省略
    // stylelint-disable-next-line
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
  }
}