@use "style/_variable" as *;
@use "sass:math";

.layoutHeader {
  position: absolute;
  z-index: 1;

  @include mq(narrow) {
    top: 0;
    left: 0;
    width: 100vw;
  }

  @include mq(wide) {
    top: rem(30);
    left: rem(16);
    width: rem(326 - 16 - 16);
  }

  &.--dark {
    @include mq(wide) {
      position: fixed;
      margin: 0 rem(16);
    }
  }
}

// 100BANCHロゴ
.organization {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}


// TOPへのリンク
.link {
  position: absolute;
  display: block;
  text-decoration: none;

  @include mq(narrow) {
    @include rect(100%, rem(60));

    top: 0;
    right: 0;
    left: 0;
    // artworkGLの再生が終わってから白背景用のヘッダー下線を可視化させる
    // スムーズにアニメーションさせるために、透過色で予め表示させておく
    border-bottom: 1px solid #e7e7e700;
    transition: border-bottom-color $t_ease;
  }

  @include mq(wide) {
    @include rect(rem(225), rem(96));

    top: rem(30 + 20);
    left: 0;
    display: block;
  }

  // 白背景のときはヘッダー下線を可視化させる
  body .--light & {
    @include mq(narrow) {
      background-color: $c_lightness;
      border-bottom: 1px solid $c_bg_number;
      box-shadow: 0 rem(-60) 0 0 $c_lightness;
    }
  }

}

// FEロゴ
.webSiteName {

  @include mq(narrow) {
    @include rect(100%, rem(60));

    display: block;
    aspect-ratio: auto;

    // ロゴ
    [class*="TitleLogo_titleLogo"] {
      position: absolute;
      top: rem(14);
      left: rem(86);
      width: rem(138.39);
    }

    // コピー
    [class*="Copy_copy"] {
      @include rect(rem(129.86), rem(20));

      position: absolute;
      top: rem(20);
      left: rem(231);
    }

  }

  @include mq(wide) {
    @include rect(rem(225), rem(96));

    top: rem(30 + 20 + 30);
    left: rem(16 + 16);
  }
}

// 13の未来イラストを素早く切替表示
.imageSwitcher {
  @include mq(narrow) {
    display: none;
  }
  @include mq(wide) {
    @include fit;

    clip-path: polygon(0 0, 71.6% 0, 71.6% 28.5%,100% 28.5%, 100% 57%, 90% 57%, 90% 84%, 49% 84%, 49% 100%, 0 100%);
    pointer-events: none;
    background-color: $c_lightness;
    opacity: 0;

    @media (any-hover: hover) {
      .link:hover & {
        z-index: 1;
        opacity: 1;
      }
    }

  }
}