@use 'style/_variable' as *;

.marqueeCatchphrase {
  position: relative;
  z-index: 2;
  height: 1px;
  pointer-events: none;
}

.frame {
  position: absolute;
  width: 100%;
  overflow: hidden;

  @include mq(narrow) {
    top: rem(58 - 6);
    height: rem(133);
  }

  @include mq(wide) {
    top: rem(170);
    height: rem(204);
  }
}

// marquee
.line {
  position: absolute;
  z-index: 0;
  display: flex;

  @include mq(narrow) {
    left: 0;
  }

  @include mq(wide) {
    left: rem(16 + 326 + 66);
  }

  // アニメーション
  &[data-intersecting] {
    transition: top 900ms ease, margin 900ms ease, opacity 900ms ease;
  }

  &[data-intersecting='false'] {
    top: 100%;
    margin-left: rem(75);
    opacity: 0;
  }

  &[data-intersecting='true'] {
    top: 0;
    margin-left: 0;
    opacity: 1;
  }
}

.text {
  @include tracking(20);

  font-family: $f_en_bold;
  font-feature-settings: 'palt';
  line-height: 1;
  color: $c_lightness;
  text-shadow: 1px 1px $c_bg_number, 1px -1px $c_bg_number, -1px 1px $c_bg_number,
    -1px -1px $c_bg_number;
  white-space: nowrap;

  @include mq(narrow) {
    font-size: rem(133);
  }

  @include mq(wide) {
    font-size: rem(204);
  }

  &[aria-hidden]:first-child {
    @include mq(narrow) {
      display: none;
    }

    @include mq(wide) {
      position: absolute;
      right: 100%;
    }
  }
}
