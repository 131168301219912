@use "style/_variable" as *;

$a_visible: fadeIn 500ms ease-out forwards;

.globalNavigation {

  @include mq(narrow) {
    position: fixed;
    right: 0;
    bottom:0;
    left: 0;
    z-index: 5;
  }

  @include mq(wide) {
    position: relative;
    display: flex; // "100年後の未来の13の当たり前"を下端に寄せるためにflexを指定
  }
}

// 100年後の未来の13の当たり前
.tagline {
  margin-top: auto;

  .--light & {
    @include mq(wide) {
      height: 0;
      margin: auto;
      overflow: hidden;
      opacity: 0;
    }
  }

  .--initialize & {
    @include mq(narrow) {
      // headless UI Transition
      &.enter {
        transition: opacity $t_enter_inner;
      }

      &.leave {
        transition: opacity $t_leave_inner;
      }

      &.enterFrom,
      &.leaveTo {
        opacity: 0;
      }

      &.enterTo,
      &.leaveFrom {
        opacity: 1;
      }
    }
  }

}

// ナビ
.indexList {
  z-index: 1;
  opacity: 0;

  .--dark & {
    color: $c_lightness;
  }

  .--light & {
    color: $c_darkness;
  }

  .--initialize & {
    @include mq(narrow) {
      // headless UI Transition
      &.enter {
        transition: opacity $t_enter_inner;
      }

      &.leave {
        transition: opacity $t_leave_inner;
      }

      &.enterFrom,
      &.leaveTo {
        opacity: 0;
      }

      &.enterTo,
      &.leaveFrom {
        opacity: 1;
      }
    }

    @include mq(wide) {
      opacity: 0;
      animation: $a_visible;
    }
  }

}

// 13の未来下層ナビ
.FuturesList {

  @include mq(narrow) {
    display: none;
  }

  @include mq(wide) {
    position: relative;
    transition: opacity $t_ease;

    .--dark & {
      height: 0;
      pointer-events: none;
      opacity: 0;
    }

    .--light & {
      opacity: 1;

      &.--invisible {
        pointer-events: none;
        opacity: 0;
      }
    }
  }
}

// 背景
@keyframes opening-screen-blur {
  0% {
    backdrop-filter: blur(0) brightness(1);
  }

  100% {
    backdrop-filter: blur(rem(25)) brightness(0.88);
  }
}

.screen {
  pointer-events: none;

  @include mq(narrow) {
    @include fit;

    backdrop-filter: blur(rem(25)) brightness(0.5);
  }

  @include mq(wide) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: rem(16);
    width: rem(326);

    .--light & {
      background-color: #ffffff0d;
      backdrop-filter: blur(rem(25)) brightness(1.05);
    }

    .--dark.--initialize & {
      // オープニングアニメーション完了後
      animation: opening-screen-blur 250ms 0ms ease-out forwards;
    }
  }

  // headless UI Transition
  &.enter {
    transition: opacity $t_enter;
  }

  &.leave {
    transition: opacity $t_leave;
  }

  &.enterFrom,
  &.leaveTo {
    opacity: 0;
  }

  &.enterTo,
  &.leaveFrom {
    opacity: 1;
  }
}

// 本文
.panel {
  display: flex;
  flex-direction: column;
  outline: none; // あえてフォーカスインジケータを非表示

  @include mq(narrow) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    gap: rem(40);
    justify-content: center;
    padding: rem(108) rem(75);
  }

  @include mq(wide) {
    width: rem(326 - 16 - 16);
  }
}

// 開閉
.opener {
  @include rect(rem(86), rem(46));

  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  padding: 0;
  font-family: $f_en_bold;
  font-size: rem(10);
  color: $c_lightness;
  background-color: #00000026;
  backdrop-filter: blur(rem(10)) brightness(0.75);
  border: 0;
  outline: none; // あえてフォーカスインジケータを非表示

  @include mq(wide) {
    display: none;
  }
}

.openerIcon {
  @include rect(rem(24), rem(6));

  position: relative;
  display: block;
  margin: 0 rem(10);

  &::before,
  &::after {
    @include rect(rem(24), 1px);

    position: absolute;
    display: block;
    margin: auto;
    content: "";
    background-color: $c_lightness;
    transition: transform $t_ease;
  }

  &::before {
    top: 0;
    left: 0;
    transform-origin: top left;

  }

  &.isOpen::before  {
    transform: rotate(12deg);
  }

  &::after {
    bottom: 0;
    left: 0;
    transform-origin: bottom left;

  }

  &.isOpen::after  {
    transform: rotate(-12deg);
  }
}
