@use 'modern-css-reset';
@use '_variable' as *;
@use 'sass:math';

// GoogleFonts
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');

// スクロールバーの幅
:root {
  --scrollbar: 0;
}

html {
  overflow: hidden; // WEB GL対応

  @include mq(narrow) {
    font-size: vw(16, 375);
  }

  @include mq(wide) {
    font-size: vw(16, 1440);
  }
}

body {
  min-height: 100%;
  margin: 0;
  overflow: hidden; // WEB GL対応
  overscroll-behavior: none; // WEB GL対応
  font-family: 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', -apple-system, BlinkMacSystemFont,
    Roboto, sans-serif;
  color: $c_dark;
  touch-action: none; // WEB GL対応
  background-color: $c_lightness;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// WEB GLの表示位置
[id='webgl-container'] {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

// WEB GLの「13の未来」HEROイラストレーション表示位置
[class='illustPosition'] {
  position: absolute;

  @include mq(narrow) {
    top: rem(60 + 25);
    right: 0;
    left: rem(15);
    width: rem(375 - 15);
    height: rem((375 - 15) * 0.5);
  }

  @include mq(wide) {
    top: rem(32);
    right: calc(rem(32) + var(--scrollbar));
    left: rem(16 + 326 + 66);
    width: calc(100% - rem(16 + 326 + 66 + 32) - var(--scrollbar));
    aspect-ratio: math.div(2,1);
  }
}

// 汎用class: モバイルレイアウトのみ可視化
.showNarrow {
  @include mq(wide) {
    display: none !important;
  }
}

// 汎用class: ワイドスクリーンレイアウトのみ可視化
.showWide {
  @include mq(narrow) {
    display: none !important;
  }
}
