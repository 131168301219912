/**
* Tracking
* @document グラフィックツールと同じトラッキング値を引数へ指定すると、同じ見た目にする。
* @param {Number} $s - Tracking value
* @example
*   .foo {
*     @include tracking(50);
*   }
*   // => text-indent: -0.025em;
*   // => letter-spacing: 0.05em;
*/
@mixin tracking($s: 50) {
  $val: 0.001 * $s * 1em;

  text-indent: -0.5 * $val;
  letter-spacing: $val;
}
