@use "style/_variable" as *;

.indexList {
  position: relative;

  @include mq(narrow) {
    display: flex;
    flex-direction: column;
    gap: rem(48);
    padding: rem(32) rem(15) rem(96);
  }

  @include mq(wide) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(54) rem(60);
    padding: rem(96);
    margin-left: rem(16 + 326);
  }

  // 本文にオーバーレイブレンドのエフェクトを被せるために、全く同じ内容を2回出力し片方を非インタラクティブにしている
  &[aria-hidden="true"] {
    position: absolute;
    pointer-events: none;
    mix-blend-mode: overlay;
  }

}
