@use 'style/_variable' as *;

.event {
  position: relative;
  z-index: 1;

  @include mq(narrow) {
    // futureDetail側で余白設定が難しいため例外的にここでpadding設定
    padding: 0 rem(15) rem(90);
    margin-top: rem(88);

    // [class*='Project_project'] + & {
    //   margin: rem(90) 0 0;
    // }

    // [class*='Report_report'] + & {
    //   // 直前のREPORTで横スクロールバースペースのため設けたpadding-bottom: rem(15)を差し引く
    //   margin: rem(90 - 15) 0 0;
    // }
  }
  @include mq(wide) {
    padding: 0 rem(32) rem(126) rem(16 + 326 + 66);
    margin-top: rem(160);
  }
}

.list {
  display: flex;
  flex-direction: column;

  @include mq(narrow) {
    gap: rem(36);
    margin: rem(22) 0 0;
  }

  @include mq(wide) {
    gap: rem(56);
    margin: rem(26) 0 0;
  }
}
