@use "style/_variable" as *;
@use "sass:math";

$n: 25; // フレーム数

.imageSwitcher {
  position: relative;
}

.item {
  @include fit;

  animation-name: switcher;
  animation-duration: #{$n * 100}ms; // $nフレーム1周の時間
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @for $i from 1 through $n {
    &:nth-child(#{$i}) {
      z-index: #{$n - $i};
      animation-delay: -#{($i - 1) * 100}ms;
    }
  }
}

@keyframes switcher {
  @for $i from 1 through $n {
    #{math.div(100 * ($i - 1), $n)}% { z-index: $n - $i; }
    #{math.div(100 * $i, $n) - 0.1}% { z-index: $n - $i; }
    #{math.div(100 * $i, $n)}% { z-index: $n - $i + 1; }
  }
  100% { z-index: $n - 1; }
}
