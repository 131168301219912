@use "style/_variable" as *;

.signalItem {
  @include rect(100%);
  @include tmp_card-shadow;

  position: relative;
  display: block;
  overflow: hidden;
  object-fit: contain;

  @media (any-hover: hover) {
    &::after {
      @include tmp_stripe($m: rem(20), $size: 45);

      content: '';
      opacity: 0;
      transition: opacity $t_ease;
    }

    &:hover::after {
      opacity: 1;
    }
  }
}


.image {
  @include rect(100%);
}