// トランジションの汎用設定
$t_duration: 250;

$t_ease: #{$t_duration * 1ms} ease;

$t_opening: 750ms ease;

$t_enter: #{$t_duration * 1ms} ease-out;
$t_leave: #{$t_duration * 1ms} #{$t_duration * 0.5ms} ease-in;
$t_enter_inner: #{$t_duration * 1ms} #{$t_duration * 0.5ms} ease-out;
$t_leave_inner: #{$t_duration * 1ms} ease-in;

$t_transition_enter: #{$t_duration * 1.5ms} #{$t_duration * 0.5ms} ease-out;

$t_easeOutCirc: cubic-bezier(0, 0.55, 0.45, 1);
