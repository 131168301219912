@use 'style/_variable' as *;

// 題字
.title {
  @include tracking(80);

  font-weight: lighter;
  font-feature-settings: 'palt';
  line-height: 1.3; // 32 / 24
  vertical-align: 1em;

  @include mq(narrow) {
    display: inline-block;
    margin: 0.9em 0 0;
    font-size: rem(24);
    vertical-align: top;
  }

  @include mq(wide) {
    font-size: rem(47);
  }

  strong {
    position: relative;
    display: inline-flex;
    padding: 0 0.05em;
    font-weight: lighter;
    white-space: nowrap;

    @include mq(narrow) {
      // highlighterで帯の装飾
      @include tmp_highlighter(rem(25), $c_lightness);

      &,
      &::before {
        background-size: 100% rem(25);
      }
    }

    @include mq(wide) {
      // highlighterで帯の装飾
      @include tmp_highlighter(rem(48), $c_lightness);

      &,
      &::before {
        background-size: 100% rem(48);
      }
    }

    // 背景の帯が文字の中心になるよう、ずらす
    &,
    &::before {
      background-position-y: 0.2em;
    }
  }
}

// 暗幕アニメーション
[data-intersecting] strong::after {
  @include fit;

  display: block;
  pointer-events: none;
  content: '';
  background-color: $c_lightness;
}

[data-intersecting='true'] strong::after {
  clip-path: inset(0 0 0 100%); // 可視
  transition: clip-path $t_ease;
}

[data-intersecting='false'] strong::after {
  clip-path: inset(0); // 不可視
}
