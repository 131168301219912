@use "style/_variable" as *;

.projectDetail {
  @include mq(narrow) {
    margin-bottom: rem(32);
  }
  @include mq(wide) {
    margin-bottom: rem(80);
  }

  * {
    // CMS入力があるコンテンツなので、折り返すよう設定する
    word-break: break-all;
  }
}

// 斜線の装飾背景
.stripeBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;

  // display: none → block タイミングでウェイトを挟みフェードイン
  opacity: 0;
  animation: fadeIn 500ms 1500ms ease forwards;

  @include mq(narrow) {
    $color: #f5f5f580; // $c_bg opacity 0.5

    background:
    linear-gradient(
      -210.5deg,
      transparent calc(51vw + rem(0)),
      $color calc(51vw + rem(0)),
      $color calc(51vw + rem(0 + 10)),
      transparent calc(51vw + rem(0 + 10)),
      transparent calc(51vw + rem(0 + 10 + 28)),
      $color calc(51vw + rem(0 + 10 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72 + 28 + 10)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72 + 28 + 10)),
    ) no-repeat left top 55vmin / 100% 100%;
  }

  @include mq(wide) {
    $color: #f5f5f54d; // $c_bg opacity 0.3

    background:
    linear-gradient(
      -210.5deg,
      transparent calc(51vw + rem(0)),
      $color calc(51vw + rem(0)),
      $color calc(51vw + rem(0 + 10)),
      transparent calc(51vw + rem(0 + 10)),
      transparent calc(51vw + rem(0 + 10 + 28)),
      $color calc(51vw + rem(0 + 10 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72 + 28)),
      $color calc(51vw + rem(0 + 10 + 28 + 72 + 28 + 10)),
      transparent calc(51vw + rem(0 + 10 + 28 + 72 + 28 + 10)),
    ) no-repeat left top #{rem(360)} / 100% 100%;
  }

  [data-artwork-delay="other"] &,
  [data-artwork-delay="future"] & {
    opacity: 0;
  }
}

// 本文
.body {
  position: relative;
  @include mq(narrow) {
    @include tmp_container_narrow;
  }
  @include mq(wide) {
    @include tmp_container_wide;
  }
}

// headline anchor
.projectDetail a:not([class]) {
  color: currentColor;
}

// headline h2
.projectDetail h2:not([class]) {
  @include tracking(20);

  position: relative;

  @include mq(narrow) {
    padding-left: rem(24 + 8);
    margin: rem(66) 0 rem(20);
    font-size: rem(20);
  }

  @include mq(wide) {
    padding-left: rem(12 + 32 + 12);
    margin: rem(136) 0 rem(30);
    font-size: rem(28);
  }

  &::before {
    position: absolute;
    display: block;
    content: '';
    background-color: $c_darkness;
    transform: skewY(-30deg);
    transform-origin: left top;

    @include mq(narrow) {
      @include rect(rem(20), rem(6));

      top: rem(20 * 0.9);
      left: 0;
    }

    @include mq(wide) {
      @include rect(rem(32), rem(8));

      top: rem(32 * 0.8);
      left: rem(12);
    }
  }
}

// headline h3
.projectDetail h3:not([class]) {
  @include tracking(40);

  @include mq(narrow) {
    margin: rem(66) 0 rem(20);
    font-size: rem(18);
  }
  @include mq(wide) {
    margin: rem(90) 0 rem(20);
    font-size: rem(22);
  }
}

// figure
.projectDetail figure:not([class]) {
  margin: rem(18) 0;
  text-align: center;

  @include mq(narrow) {
    font-size: rem(15);
  }
  @include mq(wide) {
    font-size: rem(14);
  }
}

.projectDetail figcaption:not([class]) {
  @include tracking(40);

  margin: 1em 0 0;
  text-align: center;

  @include mq(narrow) {
    font-size: rem(12);
  }
  @include mq(wide) {
    font-size: rem(14);
  }
}

.projectDetail figure img:not([class]) {
  width: auto;
  max-width: 100%;
  height: auto;
}

// blockquote
.projectDetail blockquote:not([class]) {
  background: linear-gradient(-30.5deg, transparent, transparent rem(30), $c_bg rem(30)) left top / 100% 100% no-repeat;

  @include mq(narrow) {
    padding: rem(36) rem(30);
    margin: rem(40) rem(-15) 0;
  }

  @include mq(wide) {
    padding: rem(42) rem(48);
    margin: rem(90) 0;
  }
}

.projectDetail blockquote:not([class]) p:not([class]) {
  @include tracking(30);

  margin: rem(18) 0 0;
  line-height: 2;
}

// list ul
.projectDetail ul:not([class]) {
  @include tracking(40);

  font-weight: 500;

  @include mq(narrow) {
    padding-left: rem(15);
    margin: rem(32) 0;
  }

  @include mq(wide) {
    padding-left: rem(36);
    margin: rem(30) 0;
  }

}

.projectDetail ul:not([class]) li:not([class]) {
  position: relative;
  list-style: none;

  @include mq(narrow) {
    font-size: rem(14);
  }

  @include mq(wide) {
    font-size: rem(18);
  }

  &::before {
    position: absolute;
    left: -1em;
    display: block;
    content: '';
    background-color: $c_darkness;
    border-radius: 50%;

    @include mq(narrow) {
      @include rect(rem(4));

      top: rem((21 - 4) * 0.5);
    }

    @include mq(wide) {
      @include rect(rem(6));

      top: rem((27 - 6) * 0.5);
    }
  }


}

// list ol
.projectDetail ol:not([class]) {
  @include tracking(40);

  font-weight: 500;

  @include mq(narrow) {
    padding-left: rem(18 + 10);
    margin: rem(30) 0;
  }

  @include mq(wide) {
    padding-left: rem(42);
    margin: rem(30) 0;
  }
}

.projectDetail ol:not([class]) li:not([class]) {
  list-style: decimal-leading-zero;

  @include mq(narrow) {
    padding-left: rem(3);
    font-size: rem(14);
  }

  @include mq(wide) {
    padding-left: rem(12);
    font-size: rem(18);
  }

  &::marker {
    font-family: $f_en_bold;
    line-height: 1;

    @include mq(narrow) {
      font-size: rem(19);
    }

    @include mq(wide) {
      font-size: rem(21);
    }

  }

}

.projectDetail ul:not([class]) li:not([class]),
.projectDetail ol:not([class]) li:not([class]) {
   ~ li:not([class]) {
    margin: rem(12) 0;
  }
}

// table
.projectDetail table:not([class]) {
  border-collapse: collapse;
  border-top: 1px solid $c_border;

  @include mq(narrow) {
    margin: rem(22) 0;
  }

  @include mq(wide) {
    width: 100%;
    margin: rem(30) 0 0;
  }
}


.projectDetail tr:not([class]) {

  @include mq(narrow) {
    display: block;
    padding: rem(16) rem(12);
    border-bottom: 1px solid $c_border;
  }
}

.projectDetail th:not([class]),
.projectDetail td:not([class]) {
  text-align: left;

  @include mq(narrow) {
    display: block;
    padding: rem(4) 0;
    font-size: rem(15);
  }
  @include mq(wide) {
    padding: rem(18) rem(16);
    font-size: rem(16);
    border-bottom: 1px solid $c_border;
  }
}