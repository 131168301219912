@use "style/_variable" as *;

.eventItem {
  color: currentColor;
  text-decoration: none;

  @include mq(narrow) {
    width: rem(323);
    margin: 0 auto;
  }

  @include mq(wide) {
    display: grid;
    grid-template-columns: rem(340) 1fr;
    gap: rem(48);
  }
}

.figure {
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 87.4%, 87.4% 100%, 0 100%);

  @include mq(narrow) {
    @include rect(rem(323), rem(182));
  }

  @include mq(wide) {
    @include rect(rem(340), rem(191));
  }

  @media (any-hover: hover) {
    &::after {
      @include tmp_stripe;

      content: '';
      opacity: 0;
      transition: opacity $t_ease;
    }

    .eventItem:hover &::after {
      opacity: 1;
    }
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// ヘッダー
.header {
  position: relative;
  display: flex;
  align-items: center;

  @include mq(narrow) {
    gap: rem(38);
    margin: rem(20) 0 0;
  }

  @include mq(wide) {
    gap: rem(40);
  }
}

// 日付
.date {
  // UniversLT-Condensed の下方余白が大きいので、上下中央揃えするため枠の余白を削る
  // ただし、誤差で文字がちぎれてほしくないので overflow: hidden はしない
  @include mq(narrow) {
    @include rect(rem(34), rem(45));

    min-width: rem(34);
  }
  @include mq(wide) {
    @include rect(rem(37), rem(50));

    min-width: rem(37);
  }


  [class*="Date_day"] {
    font-weight: normal;

    &::before {
      -webkit-text-stroke: 1.5px $c_darkness;
    }
  }
}


// プロジェクト
.project {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: rem(6);
  align-content: flex-start;

  // 斜線の区切り装飾
  &::after {
    position: absolute;
    top: 0;
    right: calc(100% + rem(9));
    bottom: 0;
    display: block;
    margin: auto 0;
    content: '';
    background-color: $c_border;
    transform: rotate(-30.5deg);


    @include mq(narrow) {
      @include rect(rem(17), 1px);
    }

    @include mq(wide) {
      @include rect(rem(17), 1px);
    }
  }

  // タグがない場合斜線をトルツメ
  &:empty::after  {
    content: none;
  }
}


.tag {
  @include tracking(20);

  display: inline-block;
  padding: rem(2.5) rem(6);
  font-weight: bold;
  line-height: 1.1;
  color: $c_lightness;
  background-color: $c_subtext;

  @include mq(narrow) {
    font-size: rem(12);
  }

  @include mq(wide) {
    font-size: rem(13);
  }
}

// title
.title {
  @include tracking(40);

  display: block;

  @include mq(narrow) {
    margin: rem(8) 0 0;
    font-size: rem(20);
  }

  @include mq(wide) {
    margin: rem(8) 0 0;
    font-size: rem(22);
  }
}

// description
.description {
  line-height: 1.6;

  // 3点リーダで省略
  // stylelint-disable-next-line
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @include mq(narrow) {
    @include tracking(90);

    margin: rem(8) 0 0;
    font-size: rem(14);
    font-weight: 500;
    -webkit-line-clamp: 4;
  }

  @include mq(wide) {
    @include tracking(30);

    margin: rem(14) 0 0;
    font-size: rem(16);
    -webkit-line-clamp: 3;
  }
}
