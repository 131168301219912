@use "style/_variable" as *;

.body {

  @include mq(wide) {
    padding: rem(20) rem(36);
    margin: rem(30) 0 0;
    background: linear-gradient($c_border, $c_border) top rem(20) left / 2px calc(100% - #{rem(20 + 20)}) no-repeat,
      linear-gradient($c_border, $c_border) top rem(20) right / 2px calc(100% - #{rem(20 + 20)}) no-repeat,
      linear-gradient(-30deg, transparent, transparent calc(50% - 2px), $c_border  calc(50% - 1.5px), $c_border 50%, transparent calc(50% + 0.25px)) top left / rem(32) rem(20) no-repeat,
      linear-gradient(30deg, transparent, transparent calc(50% - 2px), $c_border  calc(50% - 1.5px), $c_border 50%, transparent calc(50% + 0.25px)) top right / rem(32) rem(20) no-repeat,
      linear-gradient(30deg, transparent, transparent  calc(50% - 0.25px), $c_border 50%, $c_border calc(50% + 1.5px), transparent calc(50% + 2px)) bottom left / rem(32) rem(20) no-repeat,
      linear-gradient(-30deg, transparent, transparent  calc(50% - 0.25px), $c_border 50%, $c_border calc(50% + 1.5px), transparent calc(50% + 2px)) bottom right / rem(32) rem(20) no-repeat;
  }
}

.list {
  display: flex;
  flex-direction: column;
  padding: 0;

  @include mq(narrow) {
    gap: rem(24 + 24);
    margin: rem(24) 0 0;
  }

  @include mq(wide) {
    gap: rem(32);
    margin: 0;
  }
}