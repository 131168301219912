@use "style/_variable" as *;

// 日付
.date {
  display: flex;
  flex-direction: column;
  line-height: 1;
  text-align: center;
}

.monthYear {
  font-family: $f_en_bold;

  @include mq(narrow) {
    font-size: rem(11);
  }

  @include mq(wide) {
    font-size: rem(13);
  }
}

.day {
  margin: rem(5) 0 0;
  font-family: $f_en_bold;
  color: transparent;

  @include mq(narrow) {
    @include textStroke(attr(data-day), rem(0.8), #000);

    font-size: rem(37);
  }

  @include mq(wide) {
    @include textStroke(attr(data-day), rem(0.5), #000);

    font-size: rem(40);
  }
}