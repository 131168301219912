@use "style/_variable" as *;

.reports {
  @include mq(narrow) {
    @include tmp_container_narrow;

    margin-bottom: rem(32);
  }
  @include mq(wide) {
    @include tmp_container_wide;

    margin-bottom: rem(80);
  }

  // 子要素のmargin-bottomを相殺させず加算する
  &::after {
    display: table;
    content: '';
  }
}


.read {
  @include mq(narrow) {
    margin: rem(36) 0 rem(48);
  }
  @include mq(wide) {
    margin: rem(48) 0 rem(90);
  }
}

.paragraph {
  @include tracking(30);

  font-weight: 500;
  line-height: 2;

  @include mq(wide) {
    font-size: rem(15);
  }
}

.list {
  @include mq(narrow) {
    display: flex;
    flex-direction: column;
    gap: rem(40);
    max-width: min(100vw, 100%);
  }

  @include mq(wide) {
    display: grid;
    // スクロールバー常時表示のとき左右余白を詰めて横スクロールを抑止する
    grid-template-columns: repeat(auto-fit, rem(302));
    gap: rem(80) 0;
    justify-content: space-between;
    margin: rem(25) 0 0;
  }
}
