@use 'style/_variable' as *;

.mainVisual {
  position: relative;
  overflow: hidden;

  @include mq(narrow) {
    min-height: 100vmin;
    padding-bottom: rem(49.6);
  }

  @include mq(wide) {
    width: rem(1440 - 16 - 326 - 66);
    min-height: 100vh;
    padding-bottom: rem(114);
    margin: 0 0 0 auto;
  }
}

.titleTextImage {
  @include mq(narrow) {
    @include rect(rem(333), rem(97.38));
  }
  @include mq(wide) {
    @include rect(rem(553), rem(161.7));
  }
}

.bg {
  position: relative;
  width: 100%;
  height: rem(231);
  @include mq(wide) {
    height: rem(583);
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    content: '';

    @include mq(narrow) {
      @include rect(rem(297), rem(231));

      background: linear-gradient(30.5deg, transparent rem(35.5), $c_bg rem(36)) no-repeat;
    }

    @include mq(wide) {
      @include rect(rem(560), rem(583));

      background: linear-gradient(30.5deg, transparent rem(63.5), $c_bg rem(64)) no-repeat;
    }

  }

  &::after {
    position: absolute;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url('images/bg-images.webp');
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}

.textWrap {
  @include tmp_container_narrow();

  position: relative;
  width: 100%;
  margin-top: rem(30.2);
  @include mq(wide) {
    margin-top: rem(62.3);
  }

  &::before {
    position: absolute;
    top: rem(-30.2);
    left: 0;
    display: block;
    width: 100%;
    height: rem(470);
    content: '';
    background-image: url('images/text-bg-sp.png');
    background-size: cover;
    @include mq(wide) {
      top: rem(-308);
      left: -37%;
      width: 137%;
      height: rem(1482);
      background-image: url('images/text-bg-pc.png');
    }
  }
}

.head {
  position: relative;
  width: rem(333);
  @include mq(wide) {
    width: rem(553);
  }
}

.body {

  @include mq(wide) {
    margin: rem(72) rem(32) 0 0;
  }
}

.paragraph {
  position: relative;
  font-weight: 500;
  line-height: 2;

  @include mq(narrow) {
    @include tracking(30);

    margin: rem(41.3 - 8) 0 rem(-8);
    font-size: rem(16);
  }

  @include mq(wide) {
    @include tracking(20);

    font-size: rem(20);
  }

  ~ .paragraph {
    margin-top: 2em;
  }
}
