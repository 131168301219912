@use 'style/_variable' as *;

.futures {
  position: relative;
}

// マウスストーカーが画面の端に近づいたときに、横スクロールが発生する尾を防ぐ
.cursorArea {

  @include mq(narrow) {
    @include rect(0);

    pointer-events: none;
    opacity: 0;
  }
  
  @include mq(wide) {
    @include fit;

    right: var(--scrollbar);
    overflow: hidden;
    pointer-events: none;
  }
}

// イラスト背後の装飾
.rectangleDecoration {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;

  @include mq(narrow) {
    @include rect(rem(297), rem(231));

    background: linear-gradient(30.5deg, transparent rem(35.5), $c_bg rem(36)) no-repeat;
  }

  @include mq(wide) {
    @include rect(rem(560), rem(583));

    background: linear-gradient(30.5deg, transparent rem(63.5), $c_bg rem(64)) no-repeat;
  }
}
