@use "style/_variable" as *;
@use "sass:math";

.localizedTagline {
  position: relative;
  z-index: 1;
  aspect-ratio: math.div(225, 67);
  color: transparent;
}

.text {
  @include fit;

  background-color: #ffffff26;
  mask: url('./images/txt_tagline.svg') no-repeat center / contain;
  backdrop-filter: brightness(1.3);

  .localizedTagline.--minimum & {
    background: url('./images/txt_tagline.svg') no-repeat center / contain;
    mask: none;
    backdrop-filter: none;
  }

  .localizedTagline.--white & {
    background-color: $c_lightness;
  }

  .localizedTagline.--black & {
    background-color: $c_darkness;
  }
}

.accent {
  @include fit;

  background-color: #ffffff80;
  mask: url('./images/txt_tagline-accent.svg') no-repeat center / contain;
  backdrop-filter: brightness(3);

  .localizedTagline.--white & {
    background-color: $c_lightness;
  }

  .localizedTagline.--black & {
    background-color: $c_darkness;
  }
}
