@use "style/_variable" as *;
@use "sass:math";

.loading {
  @include fit;

  position: fixed;
  display: grid;
  place-items: center;
  color: $c_lightness;
  background-color: $c_darkness;
}

// progress bar
.progress {
  @include rect( min(80vw, rem(400)),rem(2));

  display: flex;
  background-color: $c_subtext;
}

.done {
  display: block;
  height: rem(2);
  background-color: $c_border;
  transition: width 1400ms ease-in-out;
}