@use 'style/_variable' as *;

.home {
  position: relative;
  color: $c_lightness;
}

.menu {
  display: grid;
  gap: rem(18) rem(12);
  padding: rem(24);
}

// ブラー背景
.screen {
  @include fit;

  position: fixed;
  right: var(--scrollbar);
  pointer-events: none;
  background-color: #00000026;
  backdrop-filter: blur(rem(9)) brightness(0.75);
}

// オープニング終了後に表示されるコンテンツ
.body {
  pointer-events: none;

  [class*="LayoutHeader_layoutHeader"],
  [class*="GlobalNavigation_indexList"],
  [class*="GlobalNavigation_tagline"],
  [class*="IndexList_indexList"] {
    opacity: 0;
  }

  &.--visible {
    pointer-events: auto;

    [class*="LayoutHeader_layoutHeader"] {
      opacity: 1;
    }

    // 遅れて表示
    [class*="IndexList_indexList"] {
      animation: fadeIn $t_ease #{$t_duration * 0.5}ms forwards;
    }

    // 遅れて表示
    [class*="GlobalNavigation_indexList"],
    [class*="GlobalNavigation_tagline"] {
      opacity: 0;
      animation: fadeIn $t_ease #{$t_duration * 0.5}ms forwards;
    }

    // スクリーン
    [class*="GlobalNavigation_screen"] {
      backdrop-filter: blur(rem(25)) brightness(0.88);
    }
  }
}