@use "style/_variable" as *;

.snsLinks {
  display: flex;
  justify-content: space-around;
  padding: rem(10) rem(24);
  background:
    linear-gradient(35deg, transparent rem(10.5), $c_lightness rem(11), $c_lightness calc(100% - rem(11)), transparent calc(100% - rem(10.5))) no-repeat;
}

.link {
  @include rect(rem(25));

  display: grid;
  place-items: center;

  @media (any-hover: hover) {
    transition: opacity $t_ease;

    &:hover {
      opacity: .7;
    }
  }
}