@use "style/_variable" as *;

.relationFutures {
  display: flex;
  align-items: center;
  padding: rem(10);
  background-color: $c_bg_number;

  @include mq(narrow) {
    gap: rem(15);
  }

  @include mq(wide) {
    gap: rem(12);
  }
}

// No {no}
.no {
  @include rect(rem(60), rem(24));
  @include tracking(10);

  position: relative;
  min-width: rem(60);
  padding: 0.3em 0 0 rem(31);
  margin: 0 0 auto;
  clip-path: polygon(0 0, 0 60%, 25% 100%, 100% 100%, 100% 40%, 75% 0, 0 0);
  font-family: $f_en_bold;
  font-size: rem(18);
  line-height: 1;
  color: $c_darkness;
  background: $c_lightness;
}

.noUnit {
  @include rect(rem(9), rem(7));

  position: absolute;
  bottom: rem(6);
  left: rem(18);
}

.label{ 
  @include tracking(80);

  margin: -0.2em 0; // line-heightの上下スペースと親要素paddingの被りを調整
  font-weight: bold;
  font-feature-settings: "palt";
  line-height: 1.7;

  @include mq(narrow) {
    font-size: rem(11);
  }

  @include mq(wide) {
    font-size: rem(14);
  }
}